import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const tagService = {
  getAll,
  getAllAdmin
};


function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/statistics/all`, requestOptions).then(handleResponse);
}
function getAllAdmin() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/statistics/admin/all`, requestOptions).then(handleResponse);
}


export default tagService;
