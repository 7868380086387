import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const discountService = {
  getDiscounts,
  create,
  getUserDiscount,
  remove,
  getItem,
  getDiscountedItems
};


function getDiscounts(query) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/discount/all?page=${query.page + 1}&limit=${query.pageSize}&fields=name&filter=${query.search}`, requestOptions).then(handleResponse);
}

function getDiscountedItems() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } }
  };
  return fetch(`${config.apiUrl}/discount/discounts/`, requestOptions).then(handleResponse);
}
function getUserDiscount(type) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/discount?type=${type}`, requestOptions).then(handleResponse);
}

function create(name, contractDiscount, questionDiscount, logo) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ name, contractDiscount, questionDiscount, logo })
  };
  return fetch(`${config.apiUrl}/discount/create/`, requestOptions).then(handleResponse);
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } }
  };
  return fetch(`${config.apiUrl}/discount/${id}`, requestOptions).then(handleResponse);
}

function getItem(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } }
  };
  return fetch(`${config.apiUrl}/discount/${id}`, requestOptions).then(handleResponse);
}

export default discountService;
