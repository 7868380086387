import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const formService = {
  createMessage,
  getAllMessages,
  createUnAuthMessage,
  getAllUnAuthMessages,
  updateMessage,
  getAuto
};

function createMessage(id, message, type, attachment, fileId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      message, id, type, attachment, fileId
    })
  };
  return fetch(`${config.apiUrl}/message/`, requestOptions).then(handleResponse);
}

function createUnAuthMessage(id = null, message, discountId = null, attachment = null, fileId = null) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      message, id, discountId, attachment, fileId
    })
  };
  return fetch(`${config.apiUrl}/message/unauth`, requestOptions).then(handleResponse);
}

function updateMessage(id, message) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      message
    })
  };
  return fetch(`${config.apiUrl}/message/${id}`, requestOptions).then(handleResponse);
}



function getAllUnAuthMessages(id) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/message/all/unauth/${id}`, requestOptions).then(handleResponse);
}
function getAllMessages(id) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/message/all/${id}`, requestOptions).then(handleResponse);
}

function getAuto(message, user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      message, user
    })
  };
  return fetch(`${config.apiUrl}/message/auto/`, requestOptions).then(handleResponse);
}


export default formService;
