import { BehaviorSubject } from 'rxjs';


import config from './config';
import { handleResponse } from '../helpers/handle-response';
import {removeNotificationSubscription} from "../push-notifications";


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
  login,
  logout,
  register,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  forgotPassword,
  resetPassword,
  handleGoogleLogin,
  handleFacebookLogin
};

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${config.apiUrl}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}

function register(name, email, password, discountId = null) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name, email, password, discountId
    })
  };

  return fetch(`${config.apiUrl}/register`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}

function logout(url, history) {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);

  if (url) {
    window.location.href=`/auth/login/${encodeURIComponent(url)}`;
  } else {
    history.push('/');
  }
}


function forgotPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  };

  return fetch(`${config.apiUrl}/forgot`, requestOptions)
    .then(handleResponse);
}

function resetPassword(id, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, password })
  };

  return fetch(`${config.apiUrl}/reset`, requestOptions)
    .then(handleResponse);
}

function handleGoogleLogin(tokenBlob) {
  const requestOptions = {
    method: 'POST',
    body: tokenBlob,
    mode: 'cors',
    cache: 'default'
  };

  return fetch(`${config.apiUrl}/auth/google`, requestOptions)
    .then(handleResponse).then(user => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    })
}
function handleFacebookLogin(tokenBlob) {
  const requestOptions = {
    method: 'POST',
    body: tokenBlob,
    mode: 'cors',
    cache: 'default'
  };

  return fetch(`${config.apiUrl}/auth/facebook`, requestOptions)
    .then(handleResponse).then(user => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    })
}
export default authenticationService;
