import React, {useEffect, useState} from 'react';
import Header from './Header';
import {Helmet} from "react-helmet";
import UnAuthChat from "../../components/UnAuthChat/UnAuthChat";
import {withRouter} from "react-router";
import Alert from "@material-ui/lab/Alert";
import discountService from "../../services/discount.service";
import {Link} from "react-router-dom";
import {useTheme} from "@material-ui/styles";
import VideoChatCommercial from "../Presentation/VideoChatCommercial";
import WizardCommercial from "../Presentation/WizardCommercial";
import AutomaticContractCommercial from "../Presentation/AutomaticContractCommercial";
import LowPricesCommercial from "../Presentation/LowPricesCommercial";
import FAQ from "../Presentation/FAQ";



function Landing(props) {

  const [member, setMember] = useState(null);
  const [name, setName] = useState('');
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if(props.match.params.id) {
      localStorage.setItem('discountId', props.match.params.id);
      setMember(props.match.params.id);
      discountService.getItem(props.match.params.id).then(result => {
        setName(result.name);
        if(result.logo) {
          setLogo(result.logo);
        }
      }).catch(err => {
        setMember(null);
      })
    }else if(localStorage.getItem('discountId') !== null) {
      console.log(localStorage.getItem('discountId'));
      const discountId = localStorage.getItem('discountId');
      setMember(discountId);
      discountService.getItem(discountId).then(result => {
        setName(result.name);
        setName(result.name);
        if(result.logo) {
          setLogo(result.logo);
        }
      }).catch(err => {
        setMember(null);
      })
    }
  }, []);

  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Legal24 e-sak</title>
      </Helmet>
      <UnAuthChat/>
      {member &&
      <Alert severity="success">Gjennom {name} får du medlemsfordeler ved å opprette en bruker på Legal24 e-sak. <Link
        style={{color: theme.palette.primary.main}} to={"/auth/register/" + member}>Klikk her for å opprette en
        bruker</Link></Alert>
      }

      <Header name={name} logo={logo}/>
      <VideoChatCommercial/>
      <WizardCommercial/>
      <AutomaticContractCommercial/>
      <LowPricesCommercial/>
      <FAQ/>
    </>
  );
}

export default withRouter(Landing);
