import config from './config';
import authHeader, { getToken } from "../helpers/auth-header";
import handleResponse from '../helpers/handle-response';

export const fileService = {
  getFiles,
  getUnfinishedFiles,
  getFinishedFileData,
  upload,
  download
};


function getFiles(query) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/file/all?page=${query.page + 1}&limit=${query.pageSize}&fields=${query.fields}&filter=${query.search}`, requestOptions).then(handleResponse);
}

function getFinishedFileData(slug) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/file/${slug}`, requestOptions).then(handleResponse);
}

function download(id, fileName) {
  window.location.href = `${config.apiUrl}/file/download/${id}/${fileName}`;
}

function upload(file) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data'  },
    body: JSON.stringify({file})
  };
  return fetch(`${config.apiUrl}/file/upload`, requestOptions).then(handleResponse);
}

function getUnfinishedFiles(query) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/file/unfinished/all?page=${query.page + 1}&limit=${query.pageSize}&fields=${query.fields}&filter=${query.search}`, requestOptions).then(handleResponse);
}

export default fileService;
