import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const caseService = {
  createCase,
  getCases,
  getUserCases,
  getCase,
  changeStatus,
  markRead,
  updateAssigned,
  addEmail,
  getUnauthCase,
  updateCase,
  getUserCasesAsAdmin
};

function createCase(message, fileId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ message, fileId })
  };
  return fetch(`${config.apiUrl}/case/`, requestOptions).then(handleResponse);
}

function updateCase(id, notes) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ notes })
  };
  return fetch(`${config.apiUrl}/case/${id}`, requestOptions).then(handleResponse);
}

function markRead(id) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id })
  };
  return fetch(`${config.apiUrl}/case/markRead`, requestOptions).then(handleResponse);
}

function changeStatus(id, newStatus) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ status: newStatus, id })
  };
  return fetch(`${config.apiUrl}/case/changeStatus`, requestOptions).then(handleResponse);
}

function updateAssigned(id, assigned) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({assigned })
  };
  return fetch(`${config.apiUrl}/case/assign/`+id, requestOptions).then(handleResponse);
}

function getCases(query) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  const url = query.search === '' ? `${config.apiUrl}/case/all?page=${query.page + 1}&limit=${query.pageSize}&fields=title&assigned=${query.assigned}&filter=${query.search}&sort=createdAt&order=-1&tags=${query.tags}&todo=${query.todo}` : `${config.apiUrl}/case/all?page=${query.page + 1}&limit=${query.pageSize}&filter=${query.search}&sort=score&order=-1&tags=${query.tags}&todo=${query.todo}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getUserCases(query) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  const url = query.search === '' ? `${config.apiUrl}/case/all/user?page=${query.page + 1}&limit=${query.pageSize}&assigned=&fields=title&filter=${query.search}&sort=createdAt&order=-1&tags=${query.tags}` : `${config.apiUrl}/case/all/user?page=${query.page + 1}&limit=${query.pageSize}&filter=${query.search}&sort=score&order=-1&tags=${query.tags}`;
  return fetch(url, requestOptions).then(handleResponse);
}
function getUserCasesAsAdmin(query, userId) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  const url = `${config.apiUrl}/case/admin/all/user/${userId}?page=${query.page + 1}&limit=${query.pageSize}&assigned=&fields=title&filter=${query.search}&sort=createdAt&order=-1&tags=${query.tags}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getCase(id) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/case/${id}`, requestOptions).then(handleResponse);
}
function getUnauthCase(id) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/case/unAuth/${id}`, requestOptions).then(handleResponse);
}
function addEmail(id, email, discount) {
  const requestOptions = { method: 'PATCH', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({email, discount })
  };
  if(id !== null) {
    return fetch(`${config.apiUrl}/case/addEmail/${id}`, requestOptions).then(handleResponse);

  }else {
    return fetch(`${config.apiUrl}/case/addEmail`, requestOptions).then(handleResponse);

  }
}

export default caseService;
