import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { animateScroll } from 'react-scroll';
import ConversationMessage from './ConversationMessage';
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import DoneIcon from '@material-ui/icons/Done';
const useStyles = makeStyles((theme) => ({
  root: {

  },
  inner: {
    padding: theme.spacing(2),
    flexGrow: 1,
  }
}));

function ConversationMessages({
  caseId, mode, messages, className, isRead, ...rest
}) {
  const classes = useStyles();

  const [oldMessages, setOldMessages] = useState([]);
  const [mounted, setMounted] = useState(true);

  const scrollTo = useRef();
  const messageRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if(scrollTo.current !== null) {
        scrollTo.current.scrollIntoView({behavior: "smooth"});
        setMounted(false);
      }
      }
    , 1000)

  }, [mounted]);

  useEffect(() => {
      if(messages.length > oldMessages.length) {
        scrollTo.current.scrollIntoView({ behavior: "smooth" });
        setOldMessages(messages);
        setMounted(true);
      }
  }, [messages])



  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.inner} id="scroll" ref={messageRef}>
        {messages.map((message, index) => (
          <>
          <ConversationMessage
            caseId={caseId}
            key={message._id}
            message={message}
          />
          {(index === messages.length - 1 && isRead) &&
          <Typography style={{display: 'flex', alignItems: 'center', padding: 5, textAlign: 'end', float: 'right', marginTop: -20, color: grey[300]}}><DoneIcon style={{marginRight: 2}}/>Lest av kunde</Typography>
          }
          </>
        ))}
        <div ref={scrollTo} />
      </div>


    </div>
  );
}

ConversationMessages.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array.isRequired
};

export default ConversationMessages;
