import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const videoService = {
  promiseVideo,
  getItem,
  markDone
};


function promiseVideo(title, time, type, question, price, caseId, message, pending) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      title, time, type, question, price, accepted: false, finished: false, caseId, message, pending
    })
  };
  return fetch(`${config.apiUrl}/video`, requestOptions).then(handleResponse);
}

function getItem(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/video/${id}`, requestOptions).then(handleResponse);
}

function markDone(id, finished) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({finished})
  };
  return fetch(`${config.apiUrl}/video/finished/${id}`, requestOptions).then(handleResponse);
}
export default videoService;
