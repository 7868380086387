import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const paymentService = {
  pay,
  latest,
  get,
  generateSecret,
  payAnything
};

function pay(id, type, elementId) {
  return fetch(`${config.apiUrl}/payment/free`, {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      id, type, elementId
    })
  }).then(handleResponse);
}
function latest(query) {
  return fetch(`${config.apiUrl}/payment/latest?page=${query.page + 1}&populate=${query.populate}&fields=${query.fields}&limit=${query.pageSize}&filter=${query.search}`, {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  }).then(handleResponse);
}
function get(userId) {
  return fetch(`${config.apiUrl}/payment/${userId}`, {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  }).then(handleResponse);
}
function generateSecret(id, type) {
  return fetch(`${config.apiUrl}/payment`, {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      id, type
    })
  }).then(handleResponse);
}

function payAnything(title, time, type, question, price, caseId, message, pending) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      title, time, type, question, price, accepted: false, finished: false, caseId, message, pending
    })
  };
  return fetch(`${config.apiUrl}/payment/anything`, requestOptions).then(handleResponse);
}
export default paymentService;
