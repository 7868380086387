import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import authenticationService from "./authentication.service";

export const wizardService = {
  create,
  getAll,
  getItemById,
  createCase,
  update,
  deleteWizard,
  changeVisibility,
  getWizardBySlug
};

function create(name, treeData, description) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ name, treeData, description })
  };
  return fetch(`${config.apiUrl}/wizard/`, requestOptions).then(handleResponse);
}

function getWizardBySlug(slug) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/wizard/slug/${slug}`, requestOptions).then(handleResponse);
}

function update(id, treeData, name, description) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ treeData, name, description })
  };
  return fetch(`${config.apiUrl}/wizard/` + id, requestOptions).then(handleResponse);
}

function getAll(query) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  const isAdmin = authenticationService.currentUserValue ? authenticationService.currentUserValue.user.role === 'admin': false;
  if(isAdmin) {
    return fetch(`${config.apiUrl}/wizard/all/admin?page=${query.page + 1}&limit=${query.pageSize}&fields=name&filter=${query.search}`, requestOptions).then(handleResponse);

  }else {
    return fetch(`${config.apiUrl}/wizard/all?page=${query.page + 1}&limit=${query.pageSize}&fields=name&filter=${query.search}`, requestOptions).then(handleResponse);

  }
}

function getItemById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/wizard/` + id, requestOptions).then(handleResponse);
}

function deleteWizard(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/wizard/` + id, requestOptions).then(handleResponse);
}

function createCase(message, pathData, wizardId, fileId = null) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ message, pathData, wizardId, fileId })
  };
  return fetch(`${config.apiUrl}/wizard/case`, requestOptions).then(handleResponse);
}
function changeVisibility(id, state) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id, visible: state })
  };
  return fetch(`${config.apiUrl}/wizard/changeVisibility`, requestOptions).then(handleResponse);
}



export default wizardService;
