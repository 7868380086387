import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const formService = {
  setForm,
  getForms,
  getForm,
  updateForm,
  deleteForm,
  changeVisibility,
  getFormBySlug
};

function setForm(name, formData, settings) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ name, formItems: formData, settings })
  };
  return fetch(`${config.apiUrl}/form/`, requestOptions).then(handleResponse);
}

function getForms(query) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/form/all?fields=name&filter=${query.filter}&page=${query.page + 1}&limit=${query.pageSize}`, requestOptions).then(handleResponse);
}

function getForm(id) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/form/${id}`, requestOptions).then(handleResponse);
}

function getFormBySlug(slug) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/form/slug/${slug}`, requestOptions).then(handleResponse);
}

function updateForm(id, name, formData, settings) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      form_id: id, name, formItems: formData, settings
    })
  };
  return fetch(`${config.apiUrl}/form/${id}`, requestOptions).then(handleResponse);
}

function deleteForm(id) {
  const requestOptions = { method: 'DELETE', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/form/${id}`, requestOptions).then(handleResponse);
}

function changeVisibility(id, state) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id, visible: state })
  };
  return fetch(`${config.apiUrl}/form/changeVisibility`, requestOptions).then(handleResponse);
}

export default formService;
