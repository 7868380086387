import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const resourceService = {
  createResource,
  getResourceById,
  getResourcesByType,
  updateResource,
  getResources,
  convert,
  promiseResource,
  acceptPromise,
  getTodo,
  completePromise,
  getFromCase,
  deleteItem
};

function createResource(title, question, answer, type, categories, price) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      ...{
        title, question, answer, type, categories, price
      }
    })
  };
  return fetch(`${config.apiUrl}/resource/`, requestOptions).then(handleResponse);
}

function completePromise(question, answer, type, categories, price, originResource) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      ...{
        question, answer, type, categories, price, originResource
      }
    })
  };
  return fetch(`${config.apiUrl}/resource/promise/complete`, requestOptions).then(handleResponse);
}

function convert(id, selectedResource, type) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id, ...selectedResource, type })
  };
  return fetch(`${config.apiUrl}/resource/convert`, requestOptions).then(handleResponse);
}

function deleteItem(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } }
  };
  return fetch(`${config.apiUrl}/resource/${id}`, requestOptions).then(handleResponse);
}


function updateResource(id, selectedResource, alert = false) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id, ...selectedResource, alert })
  };
  return fetch(`${config.apiUrl}/resource/`, requestOptions).then(handleResponse);
}

function getResourcesByType(type) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/resource/all/${type}`, requestOptions).then(handleResponse);
}

function getResources(query) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/resource/all?page=${query.page + 1}&limit=${query.pageSize}&fields=title,answer,question&filter=${query.search}`, requestOptions).then(handleResponse);
}

function getTodo(query) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/resource/all/todo?page=${query.page + 1}&limit=${query.pageSize}&fields=title&filter=${query.search}`, requestOptions).then(handleResponse);
}

function getResourceById(id) {
  const requestOptions = { method: 'GET', headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } } };
  return fetch(`${config.apiUrl}/resource/${id}`, requestOptions).then(handleResponse);
}

function promiseResource(title, time, type, question, price, caseId, message, pending) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({
      title, time, type, question, price, accepted: false, finished: false, caseId, message, pending
    })
  };
  return fetch(`${config.apiUrl}/resource/promise`, requestOptions).then(handleResponse);
}

function acceptPromise(id, caseId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id })
  };
  return fetch(`${config.apiUrl}/resource/promise/accept`, requestOptions).then(handleResponse);
}

function getFromCase(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } }
  };
  return fetch(`${config.apiUrl}/resource/case/${id}`, requestOptions).then(handleResponse);
}

export default resourceService;
