import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Header from './Header';
import Statistics from './Statistics';
import Notifications from './Notifications';
import {Helmet} from "react-helmet";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  notifications: {
    marginTop: theme.spacing(6)
  },
  projects: {
    marginTop: theme.spacing(6)
  },
  todos: {
    marginTop: theme.spacing(6)
  }
}));

function Overview() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Hjem</title>
      </Helmet>
      <Header />
      <Statistics className={classes.statistics} />
      <Notifications className={classes.notifications} />
    </Container>
  );
}

export default Overview;
