import React, {useEffect} from 'react';
import {Button, Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import usePushNotifications from "../usePushNotifications";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20
  }
}));

const PushNotifications = ({user}) => {
  const {
    userConsent,
    pushNotificationSupported,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickSendSubscriptionToPushServer,
    pushServerSubscriptionId,
    onClickSendNotification,
    error,
    loading
  } = usePushNotifications();



  const isConsentGranted = userConsent === "granted";

  useEffect(() => {
    if(!isConsentGranted) {
      onClickAskUserPermission();
    }else if(isConsentGranted && !userSubscription) {
      onClickSusbribeToPushNotification();
    }else if(!pushServerSubscriptionId && userSubscription) {
      onClickSendSubscriptionToPushServer();
    }
  }, [userConsent, pushServerSubscriptionId, userSubscription]);
  const classes = useStyles();

  return (
    <div className={classes.root}>

      {!isConsentGranted &&
      <Alert severity="warning">
        <AlertTitle>Advarsel</AlertTitle>
        Du har ikke tillatt notifikasjoner fra e-sak. Dette innebærer at du ikke får varsler når det kommer inn nye {user ? 'meldinger ' : 'saker '}
        og du ikke aktivt følger med.
      </Alert>
      }
      {!pushNotificationSupported &&
      <Alert severity="warning">
        <AlertTitle>Advarsel</AlertTitle>
        Nettleseren din støtter ikke varsler. Dette innebærer at du ikke får varsler når det kommer inn nye {user ? 'meldinger ' : 'saker '}
        og du ikke aktivt følger med.
      </Alert>

      }


      </div>
  );
};

export default PushNotifications;
