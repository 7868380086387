import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import FileDisplay from "./FileDisplay";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }

  }
}));

const WizardData = ({data}) => {
  const classes = useStyles();

  function calculateAnswer(data) {
      switch (data.component) {
        case "DropDownInputCreator":
          return data.elements[data.selectedElementIndex].value;
        case "DateInputCreator":
          return new Date(data.value).toLocaleDateString();
        case "TextInputCreator":
          return data.value;
        default:
          return null;
      }
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">Fra Wizards:</Typography><br />
      {data.pathData.map(question => {
        const data = question.node.data;
        return <div className={classes.question}>
        <bold><Typography variant="subtitle2">{data.question}</Typography></bold>
        <Typography gutterBottom>{calculateAnswer(data)}</Typography>
        </div>
      })}
      {data.files &&
        <FileDisplay id={data.files._id} files={data.files.files} />
      }
    </Paper>
  );
};

export default WizardData;
