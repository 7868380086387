import React from 'react';
import { makeStyles } from '@material-ui/core';
import CreatePromise from 'src/views/AdminConversation/components/PromiseList/CreatePromise';


import Promise from 'src/views/AdminConversation/components/PromiseList/Promise';
import AnswerPromise from 'src/views/AdminConversation/components/PromiseList/AnswerPromise';
import FinishedPromise from 'src/views/AdminConversation/components/PromiseList/FinishedPromise';


const useStyles = makeStyles((theme) => ({

  root: {}
}));

const PromiseList = ({
  promises, onCreate, onDelete, sendOffer, type, caseId
}) => {
  const classes = useStyles();
  if (promises.length > 0) {
    const current = -1;
    return (
      <div className={classes.root}>
        {promises.filter((element) => type.includes(element.type)).map((promise, index) => {
          if ((promise.type === 'new' || promise.type === 'video' || promise.type === 'any')) {
            return (
              <CreatePromise
                pending={promise.pending}
                key={index}
                question={promise.question}
                title={promise.title}
                price={promise.price}
                type={promise.type}
                isNew={promise.new}
                caseId={promise.caseId}
                index={index}
                onCreate={onCreate}
                onDelete={() => onDelete(current, index)}
                sendOffer={sendOffer}
              />
            );
          } if (promise.type === 'promise' && promise.accepted === true && promise.finished === false) {
            return (<AnswerPromise caseId={caseId} key={index} id={promise._id} onCreate={onCreate} index={index} />);
          } if (promise.type === 'promise' && promise.accepted === false && promise.finished === false) {
            return null;
          } if (promise.type === 'answer' && promise.accepted === true && promise.finished === true) {
            return (<FinishedPromise key={index} resource={promise} onCreate={onCreate} />);
          } if (promise.type === 'video' && promise.payment === false) {
            return null;
          }
          return null;
        })}
      </div>
    );
  }
  return null;
};

export default PromiseList;
