import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import formService from "../../services/form.service";
import wizardService from "../../services/wizard.service";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Zoom from "@material-ui/core/Zoom";
import ContractIcon from '@material-ui/icons/BorderColor';
import WizardIcon from '@material-ui/icons/AccountTree';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 40
  },
  input: {
    width: "100%",
    padding: "5px 10px 5px 10px"
  },
  searchResult: {
    minHeight: 100
  },
  searchItem: {
    height: 50,
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    '& Typography': {
      alignItems: 'center',
      display: 'flex'
    }
  },
  divider: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid grey",
    lineHeight: "0.1em",
    margin: "40px 0px 40px 0px",
    color: 'grey'
  },
  text: {
    padding: '0px 10px',
    background: '#fff',
    lineHeight: '0.1em',
    color: 'grey'
  },
  contractIcon: {
    color: theme.palette.primary.main,
    marginRight: 10
  },
  wizardIcon: {
    color: theme.palette.secondary.main,
    marginRight: 10
  }
}));

const ContentSearch = () => {

  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (search.length > 0) {


      formService.getForms({
        filter: search
      }).then(forms => {
        wizardService.getAll({
          search: search
        }).then(wizards => {
          const data = [...wizards.docs.map(element => ({
            name: element.name,
            type: "wizard",
            id: element._id,
            visible: element.visible
          })), ...forms.docs.map(element => ({ name: element.name, type: "contract", id: element.id, visible: element.visible }))].filter(element => element.visible === true);
          console.log(data);
          setResults(data);
        });
      });
    }else {
      setResults([]);
    }
  }, [search]);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Input type="text" value={search} onChange={(e) => setSearch(e.target.value)}
               placeholder="Søk etter veiledningen / kontrakten du trenger..." className={classes.input} disableUnderline/>
      </Paper>
      <br />
      {results.length === 0 &&
      <Typography>For eksempel: "reklamasjon bruktbolig" eller "kjøpekontrakt".</Typography>
      }
      {results.length > 0 &&
      <div className={classes.searchResult}>
        {results.map((result, index) => {
          return <Zoom in><RouterLink to={() => {
            return result.type === "wizard" ? "/wizard/display/" + result.id : "/createContract/" + result.id;
          }}>
            <div key={index} className={classes.searchItem}>
              {result.type === 'contract' &&
              <><ContractIcon className={classes.contractIcon}/>  <Typography>   <b>Opprett kontrakt: {result.name}</b></Typography></>
              }
              {result.type === 'wizard' &&
              <><WizardIcon className={classes.wizardIcon}/>  <Typography>   <b>Automatisk rådgivning: {result.name}</b></Typography></>

              }

            </div>
          </RouterLink></Zoom>;
        })}
      </div>
      }
    </div>
  );
};

export default ContentSearch;
