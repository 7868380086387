import React, {useEffect, useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import resourceService from "src/services/resource.service";
import {withSnackbar} from "notistack";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Slide from "@material-ui/core/Slide";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import Tooltip from "@material-ui/core/Tooltip";
import SendIcon from "@material-ui/icons/Send";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from 'react-swipeable-views';
import videoService from "../../../../services/video.service";
import paymentService from "../../../../services/payment.service";


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    position: "relative"
  },
  createAnswer: {
    overflow: "hidden",
    position: "relative"
  },
  input: {
    width: "100%"
  },
  overlay: {
    zIndex: 99,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: theme.palette.success.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "0.8"
  },
  text: {
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: 20
  },
  fade: {
    opacity: "0.8"
  },
  delete: {
    position: "absolute",
    top: -10,
    right: -10,
    zIndex: 9999,
    background: theme.palette.primary.main,
    color: "white"
  },
  button: {
    zIndex: 10
  },
  icon: {
    marginRight: 10
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const CreatePromise = ({
                         caseId, question, price, onCreate, onDelete, enqueueSnackbar, sendOffer, index, type
                       }) => {
  const [editQuestion, setEditQuestion] = useState(question);
  const [editPrice, setPrice] = useState(price);
  const [message, setMessage] = useState('Hei! Våre advokater har sett gjennom din sak og kan tilby følgende:');
  const [transition, setTransition] = useState(true);
  const [value, setValue] = useState(0);
  const [title, setTitle] = useState(value === 0 ? 'Engangsbeløp' : value === 1 ? 'Svar på henvendelse' : 'Tilbud om videochat');
  const [time, setTime] = useState('');

  useEffect(() => {
    setTitle(value === 0 ? 'Engangsbeløp' : value === 1 ? 'Svar på henvendelse' : 'Tilbud om videochat');
  }, [value]);


  function submit(type) {
    if(type === 'new') {
      resourceService.promiseResource(title, time, 'new', editQuestion, editPrice, caseId, message, true).then((result) => {
        onCreate(result, index, message);
      }).catch((err) => {
        console.log(err);
      });
    }else if(type === 'video') {
      videoService.promiseVideo(title, time, 'video', editQuestion, editPrice, caseId, message, true).then((result) => {
        onCreate(result, index, message);
      }).catch((err) => {
        console.log(err);
      });
    }else if(type === 'any') {
      paymentService.payAnything(title, time, 'any', editQuestion, editPrice, caseId, message, true).then((result) => {
        onCreate(result, index, message);
      }).catch((err) => {
        console.log(err);
      });
    }

  }


  function deleteOffer() {
    onDelete();
  }

  function initiate() {
    setTransition(false);
    sendOffer(message);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grow in={transition}>
      <Paper className={classes.root}>
        <Tooltip title="Slett og gå tilbake til melding">
          <IconButton aria-label="delete" className={classes.delete} onClick={() => deleteOffer()}>
            <DeleteIcon/>
          </IconButton>
        </Tooltip>
        <Grid
          className={classes.createAnswer}
          container
          spacing={0}
        >

          <Grid item xs={12} container spacing={3} style={{padding: 20}}>
            <Grid
              xs={12}
              item
              style={{width: '100%'}}
            >
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Engangsbeløp" {...a11yProps(0)} />
                  <Tab label="Spørsmål og svar" {...a11yProps(1)} />
                  <Tab label="Videokonsultasjon" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Belast engangsbeløp</Typography>
                      <Typography>Bruk denne hvis saken løses utenfor e-sak, for eksempel som en telefonsamtale. Husk i så fall å skrive i tittelen
                      hva kunden har betalt for.</Typography>
                      <br/>
                      <TextField
                        className={classes.input}
                        multiline
                        onChange={(e) => setEditQuestion(e.target.value)}
                        label="Skriv inn kundens spørsmål"
                        variant="outlined"
                        type="text"
                        value={editQuestion}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        multiline
                        onChange={(e) => setMessage(e.target.value)}
                        label="Skriv inn din melding"
                        variant="outlined"
                        type="text"
                        value={message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        onChange={(e) => setPrice(e.target.value)}
                        label="Pris"
                        type="number"
                        value={editPrice}
                        variant="outlined"
                      />
                      <Grid item xs={12}>
                        <br/>

                        <TextField
                          className={classes.input}
                          multiline
                          onChange={(e) => setTime(e.target.value)}
                          label="Behandlingstid i minutter (ikke påkrevet)"
                          variant="outlined"
                          type="number"
                          value={time}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br/>

                        <TextField
                          className={classes.input}
                          multiline
                          onChange={(e) => setTitle(e.target.value)}
                          label="Tittel på betalingen"
                          variant="outlined"
                          type="text"
                          value={title}
                        />
                      </Grid>

                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" onClick={() => submit('any')} color="primary" className={classes.button}>Opprett
                        tilbud</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Tilby svar på konkret spørsmål</Typography>
                    <Typography>Bruk denne hvis kunden skriver en lang melding som krever et langt svar. Dere svarer på henvendelsen i en boks som dukker opp
                    etter at kunden har betalt.</Typography>

                    <br/>
                    <TextField
                      className={classes.input}
                      multiline
                      onChange={(e) => setEditQuestion(e.target.value)}
                      label="Skriv inn kundens spørsmål"
                      variant="outlined"
                      type="text"
                      value={editQuestion}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.input}
                      multiline
                      onChange={(e) => setMessage(e.target.value)}
                      label="Skriv inn din melding"
                      variant="outlined"
                      type="text"
                      value={message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.input}
                      onChange={(e) => setPrice(e.target.value)}
                      label="Pris"
                      type="number"
                      value={editPrice}
                      variant="outlined"
                    />
                    <Grid item xs={12}>
                      <br/>

                      <TextField
                        className={classes.input}
                        multiline
                        onChange={(e) => setTime(e.target.value)}
                        label="Behandlingstid i minutter (ikke påkrevet)"
                        variant="outlined"
                        type="number"
                        value={time}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <br/>

                      <TextField
                        className={classes.input}
                        multiline
                        onChange={(e) => setTitle(e.target.value)}
                        label="Tittel på betalingen"
                        variant="outlined"
                        type="text"
                        value={title}
                      />
                    </Grid>

                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" onClick={() => submit('new')} color="primary" className={classes.button}>Opprett
                      tilbud</Button>
                  </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Gi tilbud på videokonsultasjon</Typography>
                      <Typography>Bruk denne hvis du vil ta en prat med kunden i et videochatterom her i e-sak.</Typography>
                      <br/>
                      <TextField
                        className={classes.input}
                        multiline
                        onChange={(e) => setEditQuestion(e.target.value)}
                        label="Skriv inn kundens spørsmål"
                        variant="outlined"
                        type="text"
                        value={editQuestion}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        multiline
                        onChange={(e) => setMessage(e.target.value)}
                        label="Skriv inn din melding"
                        variant="outlined"
                        type="text"
                        value={message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.input}
                        onChange={(e) => setPrice(e.target.value)}
                        label="Pris"
                        type="number"
                        value={editPrice}
                        variant="outlined"
                      />
                      <Grid item xs={12}>
                        <br/>

                        <TextField
                          className={classes.input}
                          multiline
                          onChange={(e) => setTime(e.target.value)}
                          label="Behandlingstid i minutter (ikke påkrevet)"
                          variant="outlined"
                          type="number"
                          value={time}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br/>

                        <TextField
                          className={classes.input}
                          multiline
                          onChange={(e) => setTitle(e.target.value)}
                          label="Tittel på betalingen"
                          variant="outlined"
                          type="text"
                          value={title}
                        />
                      </Grid>

                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" onClick={() => submit('video')} color="primary" className={classes.button}>Opprett
                        tilbud</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grid>

      </Paper>
    </Grow>
  );
};

export default withSnackbar(CreatePromise);
