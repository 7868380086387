import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Divider, IconButton, Input, Paper, Tooltip
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import config from "../../../services/config";
import AttachmentIcon from '@material-ui/icons/Attachment';
import FilesDropzone from "../../FilesDropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(0.5, 2)
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  }
}));

function ConversationForm({
  helperText, store, className, onSend,isOpen, handleOpen, message, setMessage, onAddPromise, handleFileChange, mode, multiline,caseType, ...rest
}) {
  const classes = useStyles();

  useEffect(() => {
    if (store) {
      if (localStorage.getItem('tmpMessage')) {
        setMessage(localStorage.getItem('tmpMessage'));
      }
    }
  }, [store]);


  function sendMessage(value) {
    onSend(message);
    localStorage.removeItem('tmpMessage');
  }
  function handleChange(e) {
    setMessage(e.target.value);

    if(store) {
      localStorage.setItem('tmpMessage', e.target.value);
    }
  }
  function handleKeyPress(e) {
    if (e.keyCode === 13) {
      if(e.shiftKey) {

      }else {
        e.preventDefault();
        onSend(message);
        localStorage.removeItem('tmpMessage');
      }
    }
  }



  return (
    <div>
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Paper
        className={classes.paper}
        elevation={1}
      >
        <Input
          className={classes.input}
          disableUnderline
          onChange={(e) => handleChange(e)}
          placeholder={helperText || 'Skriv inn din melding...'}
          value={message}
          onKeyDown={handleKeyPress}
          multiline={multiline}
        />
      </Paper>
      {(mode !== 'unauth' && mode !== 'unauth_expanded') &&
      <Tooltip title="Legg ved noe...">
      <IconButton
        color={message.length > 0 ? 'primary' : 'default'}
        onClick={() => handleOpen()}
      >
        <AttachmentIcon />
      </IconButton>

    </Tooltip>
      }
      <Tooltip title="Send">
        <IconButton
          color={message.length > 0 ? 'primary' : 'default'}
          onClick={() => sendMessage(message)}
        >
          <SendIcon />
        </IconButton>
      </Tooltip>
      <Divider className={classes.divider} />
      {mode === 'admin'
      && (
        <>
          <Tooltip title={caseType !== 'unauth' ? 'Få kunden til å betale et engangsbeløp.' : 'Be kunde om å logge inn/registrere før tilbud.'}>

            <Button onClick={() => onAddPromise(caseType)}>{caseType !== 'unauth' ? 'Gi et tilbud' : 'Be kunde autentisere'}</Button>
          </Tooltip>
        </>
      )}

    </div>
      {(isOpen && mode !== 'unauth' && mode !== 'unauth_expanded') &&
      <FilesDropzone fileChange={handleFileChange} />
      }
    </div>
  );
}

ConversationForm.propTypes = {
  className: PropTypes.string
};

export default ConversationForm;
