/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import ErrorLayout from './layouts/Error';
import Dashboard from './layouts/Dashboard';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';

export default [
    {
        path: '/auth',
        component: Dashboard,
        routes: [
            {
                path: '/auth/login/:continue?',
                exact: true,
                component: lazy(() => import('src/views/SignIn'))
            },
            {
                path: '/auth/register/:id?',
                exact: true,
                component: lazy(() => import('src/views/SignUp'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
    }
]
},
{
    path: '/errors',
        component: ErrorLayout,
    routes: [
    {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
    },
    {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
    },
    {
        component: () => <Redirect to="/errors/error-404" />
}
]
},
{
    route: '*',
        component: Dashboard,
    routes: [
    {
        path: '/innstillinger/:tab?',
        exact: true,
        component: lazy(() => import('src/views/UserSettings'))
    },
    {
        path: '/wizard/display/5e56dbb0caaaff0012e50e0d',
        exact: true,
        component: () => <Redirect to="/veileder/kjøp-av-bruktbolig-reklamasjon" />
    },
    {
        path: '/wizard/display/5e7d2a34d6d13d00187ffd2a',
        exact: true,
        component: () => <Redirect to="/veileder/advokatforsikring-meld-din-sak" />
    },
    {
        path: '/createConversation',
            exact: true,
        component: () => <Redirect to="/advokatchat" />
    },
    {
        path: '/videochat/:room?',
        exact: true,
        component: lazy(() => import('src/views/VideoChat/index'))
    },
    {
        path: '/kontrakt/:id',
        exact: true,
        component: lazy(() => import('src/views/CreateContract'))
    },
    {
        path: '/kontrakt/:id/:type?',
        exact: true,
        component: lazy(() => import('src/views/CreateContract'))
    },
    {
        path: '/advokatchat',
        exact: true,
        component: lazy(() => import('src/views/CreateConversation'))
    },
    {
        path: '/mine-samtaler',
        exact: true,
        component: lazy(() => import('src/views/UserConversationList'))
    },
    {
        path: '/samtale/:id',
        exact: true,
        component: lazy(() => import('src/views/Conversation'))
    },
    {
        path: '/hjem',
        exact: true,
        component: lazy(() => import('src/views/Overview'))
    },
    {
        path: '/forms/:id?',
        exact: true,
        component: lazy(() => import('src/views/FormEdit/FormCreator/FormCreator'))
    },
    {
        path: '/contracts/:id',
        exact: true,
        component: lazy(() => import('src/views/ContractEdit'))
    },
    {
        path: '/cases/:id',
        exact: true,
        component: lazy(() => import('src/views/AdminConversation'))
    },
    {
        path: '/adminConversationList/:page?',
        exact: false,
        component: lazy(() => import('src/views/AdminConversationList'))
    },
    {
        path: '/forgot/:id?',
        exact: true,
        component: lazy(() => import('src/views/ForgotPassword'))
    },
    {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/views/Dashboard'))
    },
    {
        path: '/user/:id?/:tab?',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
    },
    {
        path: '/users',
        exact: true,
        component: lazy(() => import('src/views/CustomerList'))
    },
    {
        path: '/kontrakter/:tab?',
        exact: true,
        component: lazy(() => import('src/views/ContractList'))
    },
    {
        path: '/discounts',
        exact: true,
        component: lazy(() => import('src/views/DiscountList'))
    },
    {
        path: '/overview',
        exact: true,
        component: OverviewView
    },
    {
        path: '/chat/:id?',
        exact: false,
        component: lazy(() => import('src/views/UnauthChat/UnauthChat'))
    },
    {
        path: '/velkommen/:id?',
        exact: false,
        component: PresentationView
    },
    {
        path: '/presentation/:id?',
        exact: false,
        component: PresentationView
    },
    {
        path: '/landing/:id?',
        exact: false,
        component: lazy(() => import('src/views/Landing/'))
    },
    {
        path: '/veiledere/alle',
        exact: true,
        component: lazy(() => import('src/views/Wizard/list'))
    },
    {
        path: '/wizard/edit/:id?',
        exact: true,
        component: lazy(() => import('src/views/Wizard/Edit/components/tree'))
    },
    {
        path: '/veiledere',
        exact: true,
        component: lazy(() => import('src/views/Wizard/WizardList'))
    },
    {
        path: '/veileder/:slug',
        exact: true,
        component: lazy(() => import('src/views/Wizard/DisplayWizard'))
    },
    {
        path: '/vilkår',
        exact: true,
        component: lazy(() => import('src/views/Terms'))
    },
    {
        path: '/adminsettings/:tab?',
        exact: true,
        component: lazy(() => import('src/views/GlobalSettings'))
    },
    {
        path: '/',
            exact: true,
        component: PresentationView
    },
    {
        component: () => <Redirect to="/errors/error-404" />
    }
]
}
];
