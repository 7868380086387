import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  props: {
    MuiTablePagination: {
      backIconButtonText: 'Forrige side',
      labelRowsPerPage: 'Rader:',
      labelDisplayedRows: ({ from, to, count }) => `${from}-${to === -1 ? count : to} av ${count}`,
      nextIconButtonText: 'Neste side',
    },
    MuiRating: {
      getLabelText: (value) => `${value} Stjerne${value !== 1 ? 'r' : ''}`,
    },
    MuiAutocomplete: {
      clearText: 'Resett',
      closeText: 'Lukk',
      loadingText: 'Laster…',
      noOptionsText: 'Ingen alternativer',
      openText: 'Åpne',
    },
  },
});

export default theme;
