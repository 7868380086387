import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  }
}));

const SuccessButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(Button);

const ConversionDisplay = ({ history, data }) => {
  const classes = useStyles();


  function handleClick() {
    history.push({
      pathname:'/auth/register',
      state: {
        type: 'conversion',
        data: {
          conversation: data.conversation
        }
      }
    });
  }

  return (
    <div className={classes.root}>
      {!data.finished &&
      <SuccessButton startIcon={<ArrowForwardIcon />} variant="contained" onClick={() => handleClick()}>Trykk for å fortsette</SuccessButton>

      }
      {data.finished &&
        <Typography>Du autentiserte deg.</Typography>
      }
    </div>
  );
};

export default withRouter(ConversionDisplay);
