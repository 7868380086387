import React, { useEffect, useState } from "react";

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Button
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import authenticationService from '../../services/authentication.service';
import Tooltip from "@material-ui/core/Tooltip";
import ContactIcon from "@material-ui/icons/Forum";
import ContractIcon from "@material-ui/icons/BorderColor";
import WizardIcon from "@material-ui/icons/AccountTree";
import usePushNotifications from "../../usePushNotifications";
import Error from "../../layouts/Error";
import PushNotifications from "../../components/PushNotifications";
import GridActionButton from "../../components/GridAction/GridActionButton";
import VoiceChatIcon from "@material-ui/icons/VoiceChat";
import discountService from "../../services/discount.service";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20
  },
  summaryButton: {
    backgroundColor: theme.palette.common.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    textAlign: 'center',
    width: '100%'
  },
}));

function Header({ className, history, ...rest }) {
  const [additionDiscount, setAdditionalDiscount] = useState(false);

  const classes = useStyles();
  const session = authenticationService.currentUserValue.user;


  useEffect(() => {
    discountService.getDiscountedItems().then(result => {
      if(result) {
        if (result.freeItems.length > 0) {
          setAdditionalDiscount(true);
        }
      }
    })
  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Hjem
          </Typography>
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
          >
Hei,
            {' '}
            {session.name}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
          >
            Hva kan vi hjelpe med?
          </Typography><br /> <br/>
          <Grid container spacing={5}>
            <Grid item xs={6} md={3} lg={3} container justify='center'>
              <GridActionButton
                onClick={() => history.push("/advokatchat")}
                icon={<ContactIcon fontSize='large' style={{fontSize: 70}}/>}
              >
                Start chat
              </GridActionButton>
            </Grid>
            <Grid item xs={6} md={3} lg={3} container justify='center'>

              <GridActionButton
                onClick={() => history.push("/advokatchat")}
                icon={<VoiceChatIcon fontSize='large' style={{fontSize: 70}}/>}
              >
                Start videosamtale
              </GridActionButton>
            </Grid>
            <Grid item xs={6} md={3} lg={3} container justify='center'>

              <GridActionButton
                onClick={() => history.push("/kontrakter/alle")}
                badge='Automatisk'
                icon={<ContractIcon fontSize='large' style={{fontSize: 70}}/>}
              >
                Lag kontrakt
              </GridActionButton>
            </Grid>
            <Grid item xs={6} md={3} lg={3} container justify='center'>

              <GridActionButton
                badge='Automatisk'
                onClick={() => history.push("/wizard")}
                icon={<WizardIcon
                  fontSize='large' style={{fontSize: 70}}/>}
              >
                Juridisk veiledning
              </GridActionButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {session.discountId
          && (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} gutterBottom>
                Medlem av organisasjon
              </Typography>
              {!session.discountId.discountText &&


              <Typography variant="body2" component="p">
                  Som medlem av
                {' '}
                {session.discountId.name}
                {' '}
får du
                {' '}
                {session.discountId.contractDiscount}
                {' '}
% rabatt på kontrakter
                 og
                {' '}
                {session.discountId.questionDiscount}
                {' '}
% rabatt på rådgivning. Rabatten trekkes fra først når du kommer til
                betalingssiden. {additionDiscount &&
              <>I tillegg kan du opprette flere kontrakter gratis ved å velge "kontrakter" i sidemenyen.</>
              }
              </Typography>
              }
              {session.discountId.discountText &&
              <Typography variant="body2" component="p">
                {session.discountId.discountText}
              </Typography>
              }
            </CardContent>
          </Card>
          )}

        </Grid>
        {("Notification" in window) &&
          <Grid item xs={12}>
        <PushNotifications user/>
          </Grid>
        }
      </Grid>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default withRouter(Header);
