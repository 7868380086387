import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import fileService from "../../../../services/file.service";
import config from "../../../../services/config";


const useStyles = makeStyles(theme => ({
  file: {
    color: theme.palette.secondary.main,
    textDecorationLine: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'pointer',
    fontFamily: 'inherit'
  }
}));

const FileDisplay = ({files, id}) => {
  const classes = useStyles();
  function handleDownload(id, fileName) {
    fileService.download(id, fileName);
  }
  return (
    <div>
      <Typography variant="h6">Filer:</Typography>
      {files.map(file => {
        return <Typography><a className={classes.file} href={`${config.apiUrl}/file/download/${id}/${file.fullName}`} title={file.name}>{file.name}</a></Typography>
        }
      )}
    </div>
  );
};

export default FileDisplay;
