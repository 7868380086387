import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { makeStyles } from '@material-ui/styles';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupportOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  list: {
    marginTop: theme.spacing(6)
  }
}));

const faqs = [
  {
    title: 'Hvor mye koster en kontrakt?',
    description:
      'En kontrakt koster mellom 100 og 500 kroner avhengig av kompleksiteten. Har vi ikke kontrakten du trenger oppretter vi den gjerne for deg.'
  },
  {
    title: 'Hvor mye koster et svar på en henvendelse?',
    description:
      'På vår chat får du svar på ditt spørsmål, eller - hvis dette ikke er mulig - tilbud på videre oppdrag.'
  },
  {
    title: 'Hvordan snakker jeg med dere over video?',
    description:
      'Opprett en ny henvendelse med en av lenkene over og skriv en kort innledende melding. Etter at riktig fagavdeling hos oss har mottatt din henvendelse vil du kunne forespørre en videosamtale.'
  },
  {
    title:
      'Hvordan fungerer automatisk veiledning?',
    description:
      'Automatisk veiledning fungerer ved at du gjennomfører en undersøkelse rundt ditt juridiske problem. I mange tilfeller vil du kunne få et direkte svar kun av ' +
      'undersøkelsen, og hvis ikke vil våre advokater være behjelpelig med å vise deg videre. Å gjennomføre en undersøkelse er gratis og tar kort tid. Trenger du ytterligere' +
      ' rådgivning vil du få tilbud om dette i ettertid.'
  },
  {
    title:
      'Hva skjer hvis jeg har flere spørsmål når jeg kontakter dere?',
    description:
      'Prisene for den henvendelsen vi mottar vil variere avhengig av saksart. Du vil få et tilbud om pris som du kan velge å akseptere etter at vi har gjenomgått din sak.'
  },
  {
    title:
      'Hvordan fungerer elektronisk signering?',
    description:
      'Etter å ha opprettet og betalt for en kontrakt i vårt automatiske kontraktsystem, vil du kunne velge å signere denne med Bank-ID. Da vil partene få' +
      ' utsendt en lenke over e-post som de benytter for å signere dokumentet. Du vil kunne følge status på dette i vårt system, og senere laste ned den' +
      ' juridisk bindende kontrakten.'
  }
];

function FAQ({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant="h3"
        >
          Ofte stilte spørsmål
        </Typography>
        <List
          disablePadding
          className={classes.list}
        >
          {faqs.map((faq) => (
            <ListItem
              disableGutters
              key={uuid()}
            >
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText
                primary={faq.title}
                primaryTypographyProps={{ variant: 'h5' }}
                secondary={faq.description}
                secondaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          ))}
        </List>
      </Container>
    </div>
  );
}

FAQ.propTypes = {
  className: PropTypes.string
};

export default FAQ;
