import React, {Suspense, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {withSnackbar} from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import PayContract from '../Payment/PayContract';
import Spinner from '../../static/Loading/Spinner';
import WizardData from "./WizardData/WizardData";
import FileDisplay from "./WizardData/FileDisplay";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { element } from "prop-types";
import ConversionDisplay from "./ConversionDisplay";
import config from "../../../services/config";
const stripePromise = loadStripe(config.stripe_pk);

const Answer = React.lazy(() => import('./Answer/Answer'));
const Promise = React.lazy(() => import('./Promise/Promise'));

const Resource = React.lazy(() => import('./Resource/Resource'));
const VideoPromise = React.lazy(() => import('./VideoPromise/VideoPromise'));


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
    marginTop: 20
  },
  delete: {
    float: 'right'
  }
}));


const ReturnComponent = ({attachment, setForceUpdate, createMode}) => {


  if (attachment.hasOwnProperty('pathData')) {
    return <WizardData data={attachment}/>;
  } else {

    if (attachment.type === 'answer' && !attachment.payment) {
      return <Resource data={attachment} onAccept={() => setForceUpdate(true)}/>;
    } else if (attachment.type === 'answer' && attachment.payment) {
      return <Answer data={attachment}/>;

    } else if (attachment.type === 'video') {
      console.log(attachment.caseId)
      return <VideoPromise caseId={attachment.caseId} data={attachment} onAccept={() => setForceUpdate(true)}
                           createMode={createMode}/>;
    } else if (attachment.type === 'promise' || attachment.type === 'any') {
      return <Promise data={attachment} onAccept={() => setForceUpdate(true)} createMode={createMode}/>;
    } else if (attachment.type === 'file') {
      return <FileDisplay id={attachment._id} files={attachment.files}/>
    } else if (attachment.type === 'conversion') {
      return <ConversionDisplay data={attachment}/>
    }  else {
      return null;
    }
  }
};


const Attachment = ({
                      id, enqueueSnackbar, createMode, onDelete, attachment
                    }) => {
  const [, setForceUpdate] = useState(false);
  const [open, setIsOpen] = useState(false);
  const classes = useStyles();


  function handlePayment() {
    enqueueSnackbar('Ressursen er betalt for. Scroll ned for å se den!', {
      variant: 'success'
    });
    setForceUpdate(true);
    setIsOpen(false);
  }

  function handleClose() {
    console.log('CLOSED');

    setIsOpen(false);
  }


  return (

    <Paper>
      <div className={classes.root}>
        {createMode === true
        && (
          <IconButton
            onClick={() => onDelete(id)}
            color="primary"
            aria-label="upload picture"
            component="span"
            className={classes.delete}
          >
            <DeleteIcon/>
          </IconButton>
        )}

        <ReturnComponent attachment={attachment} createMode={createMode}
                         setForceUpdate={(value) => setForceUpdate(value)}/>
      </div>
      {!attachment.pathData &&
      <Elements stripe={stripePromise}>
        <PayContract
          type={attachment.type}
          id={id}
          isOpen={open}
          handleClose={() => handleClose()}
          handlePayed={() => handlePayment()}
        />
      </Elements>
      }

    </Paper>
  );
};

export default withSnackbar(Attachment);
