import React, {useEffect, useState} from "react";
import {Input, makeStyles} from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import Typography from "@material-ui/core/Typography";
import ConversationDetails from "../elements/Chat";
import messageService from "../../services/message.service";
import Button from "@material-ui/core/Button";
import Shape from "shape-library";
import Paper from "@material-ui/core/Paper";
import {withRouter} from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import caseService from "../../services/case.service";
import {withSnackbar} from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import useTheme from "@material-ui/core/styles/useTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  rootOpen: {
    [theme.breakpoints.up('xs')]: {
      width: 500,
      height: 700,
      right: 20
    },
    background: "lightgrey",
    position: "fixed",
    zIndex: 999,
    bottom: 0,
    borderRadius: "4px 4px 0px 0px",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      right: 0,
      height: 'calc(100% - 56px)'
    },
  },
  rootClose: {
    [theme.breakpoints.up('xs')]: {
      width: 500,
      right: 20,
    },
    height: 60,
    background: "lightgrey",
    position: "fixed",
    zIndex: 999,
    bottom: 0,
    borderRadius: "4px 4px 0px 0px",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      right: 0
    },
  },
  conversationDetails: {
    height: "calc(100% - 50px)"
  },
  inner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  hello: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    width: '100%',
    justifyContent: 'space-between'
  },
  emailDialog: {
    [theme.breakpoints.up('xs')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }
}));

const SuccessButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(Button);
const UnAuthChat = (props) => {
  const [open, setOpen] = useState(localStorage.getItem('tmpChat'));
  const [message, setMessage] = useState("");
  const [caseId, setCaseId] = useState(localStorage.getItem('tmpChat'));
  const [messages, setMessages] = useState([]);
  const [email, setEmail] = useState('');
  const [emailAdded, setEmailAdded] = useState(false);
  const [files, setFiles] = useState([]);
  const [discount, setDiscount] = useState(null);
  const classes = useStyles();

  function returnHeight() {
    if (messages.length > 0) {
      return {height: "calc(100% - 50px)"}

    } else {
      return {height: "calc(100% - 50px)"}
    }
  }


  useEffect(() => {
    if (localStorage.getItem("tmpChat")) {
      setCaseId(localStorage.getItem('tmpChat'));
      messageService.getAllUnAuthMessages(caseId).then((result) => {
        setMessages(result);
      });
      caseService.getUnauthCase(localStorage.getItem('tmpChat')).then(result => {
        if (result.email) {
          setEmail(result.email);
        }
      })
    }
    ;
    if (localStorage.getItem('discountId')) {
      setDiscount(localStorage.getItem('discountId'));
    }
    ;
    if (props.match.params.id) {
      setDiscount(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (caseId) {
      const interval = setInterval(() => {
        messageService.getAllUnAuthMessages(caseId).then((result) => {
          setMessages(result);
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [caseId]);

  function sendMessage(message) {
    messageService.createUnAuthMessage(caseId, message, discount).then(result => {
      setCaseId(result.conversation);
      localStorage.setItem("tmpChat", result.conversation);
      setMessage("");
      setMessages([...messages, result]);
    }).catch(error => {
      console.log(error);
    });
  }

  function closeChat() {
    setOpen(false);
  }

  function terminateChat() {
    setOpen(false);
    localStorage.removeItem("tmpChat");
    setMessage("");
    setMessages([]);
    setCaseId(null);
    setEmail(null);
    setEmailAdded(false);
  }

  function handleFileChange(newFiles) {
    setFiles(newFiles);
  }


  function addEmail() {
    caseService.addEmail(caseId, email, discount).then(result => {
      setCaseId(result._id);
      props.enqueueSnackbar('Du vil nå få varsler på e-post', {
        variant: 'success'
      });
      setEmailAdded(true);
    }).catch(err => {
      props.enqueueSnackbar("E-posten du skrev inn er ikke gyldig.", {
        variant: "error"
      });
    })
  }


  const theme = useTheme();

  if (open) {
    return (

      <div className={classes.rootOpen}>
        <div className={classes.hello}>
          <Typography variant="h3">Chat</Typography>
          <div>
            <Tooltip title="Avslutter chat og fjerner loggen">
              <Button onClick={() => terminateChat()} style={{marginRight: 10}}>Avslutt og slett</Button>
            </Tooltip>
            <Tooltip title="Lukk denne boksen midlertidig">

              <Button onClick={() => closeChat()} style={{marginRight: 10}}>Lukk</Button>
            </Tooltip>
          </div>

        </div>
        <Dialog
          open={(!caseId)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title">{"Hei! For at vi ikke skal miste kontakten må du oppgi din e-post for å fortsette."}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.emailDialog}
            >
              <Paper elevation={1}><Input autoComplete="new-password"
                                          onChange={e => setEmail(e.target.value)} value={email}
                                          style={{width: '100%', padding: theme.spacing(1, 2)}} disableUnderline
                                          type="email"
                                          placeholder="Skriv inn din e-post..."/></Paper><br/>
              <Typography style={{color: 'grey'}}>Vi bruker denne kun til å gi deg saksoppdateringer.</Typography>
              <Typography>Ved å fortsette samtykker du til vår <a href='https://legalis.no/personvern/' target="_blank"
                                                                  style={{
                                                                    borderBottom: "1px dashed black",
                                                                    color: 'inherit'
                                                                  }}>personvernerklæring</a>.</Typography>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Skal du chatte med oss må du oppgi en e-postadresse og godta personvernerklæring">
              <Button onClick={() => closeChat()} color="primary">
                Lukk chat
              </Button>
            </Tooltip>
          </DialogActions>
          <SuccessButton startIcon={<ArrowForwardIcon/>} onClick={() => addEmail()} color="primary">
            Fortsett til chat
          </SuccessButton>
        </Dialog>
        <ConversationDetails
          caseId={null}
          style={returnHeight()}
          conversation={messages}
          onSend={sendMessage}
          setMessage={(message) => setMessage(message)}
          handleFileChange={handleFileChange}
          isOpen={() => false}
          message={message}
          mode="unauth"
        />
      </div>
    );
  } else {
    return (
      <div className={classes.rootClose} onClick={() => setOpen(true)}>
        <Paper className={classes.inner}>
          <ChatIcon style={{marginLeft: 10}}/> <Typography variant="h5" style={{marginLeft: 10}}> Start en gratis chat
          uten innlogging</Typography>
        </Paper>
      </div>
    );
  }
};

export default withSnackbar(withRouter(UnAuthChat));
