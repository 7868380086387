import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import ContractIcon from '@material-ui/icons/BorderColor';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';
import { withRouter } from 'react-router-dom';
import statisticsManager from '../../services/statistics.service';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  type: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));


const icons = {
  melding: <MailIcon />,
  kontrakt: <ContractIcon />
};

function Notifications({ className, history, ...rest }) {
  const classes = useStyles();
  const [unPayed, setUnPayed] = useState(0);
  const [unRead, setUnread] = useState(0);

  const notifications = [
    {
      id: uuid(),
      value: unRead,
      type: 'melding',
      message: 'fra oss'
    },
    {
      id: uuid(),
      value: unPayed,
      type: 'kontrakt',
      message: 'er ikke betalt for'
    }
  ];

  useEffect(() => {
    statisticsManager.getAll().then((result) => {
      console.log(result);
      setUnPayed(result.unPayed);
      setUnread(result.unRead);
    });
  }, []);

  function handleRedirect(type) {
    if (type === 'kontrakt') {
      history.push('/kontrakter/uferdige', {
        unpayed: true
      });
    } else if (type === 'melding') {
      history.push('/mine-samtaler');
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <List>
        {notifications.map((notification, i) => (
          <ListItem
            divider={i < notifications.length - 1}
            key={notification.id}
          >
            <ListItemIcon>{icons[notification.type]}</ListItemIcon>
            <ListItemText>
              <Typography variant="body1">
                <span className={classes.value}>{notification.value}</span>
                {' '}
                <span className={classes.type}>
                  {notification.type}
                  {(notification.value !== 1) ? 'er' : ''}
                </span>
                {' '}
                {notification.message}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Tooltip title="Vis">
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => handleRedirect(notification.type)}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

Notifications.propTypes = {
  className: PropTypes.string
};

export default withRouter(Notifications);
