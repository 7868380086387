const apiUrl = "https://esak-backend-legal24.azurewebsites.net/";
const stripe_pk = "pk_live_bJeviri7boygqNfeRNBLIGA800jbEjaUtg";
const facebook_pk = "2748891721896787";
const google_pk =
  "395598165212-mbn7ojjg2a6uaph56rtkh9k4qokc6iff.apps.googleusercontent.com";

module.exports = {
  apiUrl: apiUrl,
  stripe_pk,
  google_pk,
  facebook_pk,
};
