import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'src/App';
import {ThemeProvider} from "@material-ui/styles";
import theme from "./theme";
import {Router} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { Provider } from "react-redux";
import { createStore } from "@andyet/simplewebrtc";
import {createBrowserHistory} from "history";
import GoogleAnalytics from "./components/GoogleAnalytics";
import CookiesNotification from './components/CookiesNotification';
const store = createStore();


const browserHistory = createBrowserHistory();


const render = Component => {

  return ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
      <CookiesNotification />

        <SnackbarProvider maxSnack={3}>

          <Router history={browserHistory}>
            <GoogleAnalytics />
            <Component/>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
  )
};
render(App);

if (module.hot) {
  // Whenever a new version of App.js is available
  module.hot.accept('./App', () => {
    // Require the new version and render it instead
    const NextApp = require('./App');
    render(NextApp);
  });
}
