import React, {Suspense, useEffect, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {LinearProgress} from '@material-ui/core';
import authenticationService from 'src/services/authentication.service';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Footer from "./Footer";
import {useLocation} from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Dashboard({route}) {
  const classes = useStyles();
  const location = useLocation();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [user, setUser] = useState();
  console.log(location);


  useEffect(() => {
    authenticationService.currentUser.subscribe((x) => setUser(x));
  }, []);
  const authStatus = () => {
    if (user) {
      return user.user.role;
    }
    return false;
  };
  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} authStatus={authStatus()}/>
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        authStatus={authStatus()}
        user={user}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress/>}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
      {location.pathname === '/presentation' &&
      <Footer/>
      }
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
