import React from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';


import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Slide from '@material-ui/core/Slide';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden'
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  overlay: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.success.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.8',
  },
  text: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  fade: {
    opacity: '0.8'
  }
}));

const FinishedPromise = ({ resource }) => {
  const classes = useStyles();
  if (resource) {
    return (
      <Zoom in>
        <Paper className={classes.root}>
          <Slide in>
            <div className={classes.overlay}>
              <Typography variant="h4" className={classes.text}>
                <CheckCircleOutlineIcon fontSize="large" color="inherit" />
                {' '}
Gjøremål fullført.
              </Typography>
            </div>
          </Slide>
          <TextField
            label="Pris"
            type="number"
            disabled
            value={resource.price}
            className={classes.input}
            variant="outlined"
          />
          <TextField
            className={classes.input}
            label="Spørsmål"
            multiline
            type="text"
            disabled
            variant="outlined"
            value={resource.question}
          />
          <TextField
            className={classes.input}
            label="Svar"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            placeholder="Svar"
            type="text"
            disabled
            value={resource.answer}
          />


          <Autocomplete
            defaultValue={resource.categories}
            freeSolo
            id="tags-filled"
            multiple
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Emneknagger"
                margin="normal"
                placeholder="Søk etter eller lag nye..."
                variant="filled"
              />
            )}
            renderTags={(value, getTagProps) => (value.map((option, index) => (
              <Chip
                label={option}
                variant="outlined"
                {...getTagProps({ index })}
              />
            )))}
          />
        </Paper>
      </Zoom>
    );
  }
  return null;
};

export default FinishedPromise;
