import React, { Component, PureComponent, useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress, Typography } from "@material-ui/core";
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import paymentService from '../../../services/payment.service';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';



function PayContract({id, type, isOpen, toDownload, enqueueSnackbar, history, handlePayed, handleClose, caseId}) {
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [progress, setProgress] = useState(false);
  const [secret, setSecret] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    console.log(id);

    if(id) {
      //Get client secret for payment
      paymentService.generateSecret(type === 'contract' ? toDownload : id, type).then(result => {
        setPrice(result.price);
        setDiscount(result.discount);
        setSecret(result.secret);
        console.log(result);
      });

    }
    if(isOpen) {
    }
  }, [id, type]);


  async function pay(ev) {
    // User clicked submit
    setProgress(true);

    const { user } = JSON.parse(localStorage.getItem('currentUser'));

    if(price !== 0) {

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const result = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.name,
          },
        }
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        setProgress(false);
        enqueueSnackbar('Kunne ikke gjennomføre betalingen', {
          variant: 'error'
        })
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          console.log(result);
          if (type === 'contract') {
            handleClose('payed');
          } else if (type === 'resource' || type === 'promise' || type === 'video') {
            handlePayed();
          }
          setProgress(false);
        } else {
          enqueueSnackbar('Kunne ikke gjennomføre betalingen', {
            variant: 'error'
          })
        }
      }
    }else {
      paymentService.pay(type === 'contract' ? toDownload : id, type, type === 'contract' ? toDownload : history.location.pathname.split('/')[2]).then(result => {
        if (type === 'contract') {
          handleClose('payed');
        } else if (type === 'resource' || type === 'promise') {
          handlePayed();
        }
        setProgress(false);
      }).catch(err => {
        enqueueSnackbar('Kunne ikke gjennomføre betalingen.', {
          variant: 'error'
        })
      })
    }

  }

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Betaling</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {discount > 0
            && (
              <Typography>
                Din rabatt:
                {' '}
                {discount}
                {' '}
                %
              </Typography>
            )}
            {type === 'promise'
            && (
              <span>
For at vi skal svare på henvendelsen din koster det
                {' '}
                {' '}
                {price}
                {' '}
                kroner.
                {discount > 0
                && <span> (med rabatt)</span>}
              </span>
            )}
            {type !== 'promise'
            && (
              <span>
Denne
                {' '}
                {type === 'contract' ? 'kontrakten' : type === 'video' ? 'videosamtalen' : 'ressursen'}
                {' '}
                koster
                {' '}
                {price}
                {' '}
                kroner å
                {' '}
                {type === 'contract' ? 'opprette' : type === 'video' ? 'starte' : 'vise'}
                .
                {discount > 0
                && <span> (med rabatt)</span>}
              </span>
            )}
            <br />
            <br />
            {(discount !== 100 && price !== 0) &&
            <CardElement />
            }
            <br />
            {progress
            && <LinearProgress />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={() => pay()} color="primary" autoFocus disabled={progress}>
            {price !== 0 ? 'Betal' : 'Videre'}
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default withSnackbar(withRouter(PayContract));
