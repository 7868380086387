import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const adminSettingsService = {
  updateMenu,
  getMenu
};
function getMenu(type) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/adminsettings/${type}`, requestOptions).then(handleResponse);
}
function updateMenu(type, menu) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ menu, type })
  };
  return fetch(`${config.apiUrl}/adminsettings/`, requestOptions).then(handleResponse);
}


export default adminSettingsService;
