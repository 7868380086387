import { authenticationService } from '../services/authentication.service';

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return { Authorization: `Bearer ${currentUser.token}` };
  }
  return {};
}

export function getToken() {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return currentUser.token;
  }
  return false;
}

export default authHeader;
