import { Chart } from 'react-chartjs-2';
import validate from 'validate.js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import { renderRoutes } from 'react-router-config';



import validators from './common/validators';
import { chartjs } from './helpers';
import routes from './routes';
import './App.css';


Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};



const render = Component => {



  return (



            renderRoutes(routes)




  );
}

export default render;
