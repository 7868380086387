import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Chip from "@material-ui/core/Chip";
import ResourcePicker from "src/components/elements/Resource/ResourcePicker";

import resourceService from "src/services/resource.service";
import tagService from "src/services/tags.service";
import { withSnackbar } from "notistack";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    position: "relative",
    overflow: "hidden"
  },
  input: {
    width: "100%",
    marginBottom: 10
  },
  overlay: {
    zIndex: 1000,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: theme.palette.success.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "0.8"
  },
  text: {
    color: "white",
    display: "flex",
    alignItems: "center"
  },
  fade: {
    opacity: "0.8"
  }
}));

const AnswerPromise = (props) => {
  const {
    id, enqueueSnackbar, onCreate, index
  } = props;
  const [selectedResource, setSelectedResource] = useState();
  const [tags, setTags] = React.useState([]);
  const [finished, setFinished] = React.useState(false);
  const [resourcePickerVisible, setResourcePickerVisible] = React.useState(false);

  function updateResource() {
    resourceService.completePromise(selectedResource.question, selectedResource.answer, "answer", selectedResource.categories, selectedResource.price, selectedResource._id).then((result) => {
      console.log(props);
      onCreate({ ...result, ...{ type: "answer" } }, index);
      setFinished(true);
    }).catch((err) => {
      console.log(err);
      setFinished(true);
    });
  }


  useEffect(() => {
    resourceService.getResourceById(id).then((data) => {
      setSelectedResource(data);
      setSelectedResource((selectedResource) => ({ ...selectedResource, type: "answer" }));
    });
    tagService.getAll().then((result) => {
      setTags(result);
    });
  }, [id]);

  function addTag(e) {
    if (e !== null && e.keyCode === 13) {
      tagService.create(e.target.value);
    }
    if (e !== null) {
      console.log(e.type);
    }
  }

  function addTemplate() {
    const oldValue = selectedResource.answer || "";

    setSelectedResource((selectedResource) => ({
      ...selectedResource,
      answer: `${"Legalis takker for registrering og bekreftelse for rådgivning. \n"
        + "\n"
        + "Vi har nå sett nærmere på Deres henvendelse og kan komme med følgende tilbakemelding:\n\n"}${oldValue
        }\n\nVi håper De er fornøyd med tjenesten og at svaret kan bidra til å løse Deres juridiske problem. Ta gjerne kontakt hvis vi på noen måte kan være til ytterligere bistand. \n`
        + "\n"
        + "Med vennlig hilsen\n"
        + "Advokatfirmaet Legalis AS\n"
        + "\n"
        + "advokat"
    }));
  }

  function handleAddResource(id, type, answer) {
    setSelectedResource((selectedResource) => ({
      ...selectedResource,
      answer
    }));
    enqueueSnackbar("Svaret er satt inn i svarfeltet oppe.", {
      variant: "success"
    });
  }

  const classes = useStyles();
  if (selectedResource) {
    return (
      <Zoom in>
        <Paper className={classes.root}>
          <Slide in={finished} direction="up">
            <div className={classes.overlay}>
              <Typography variant="h4" className={classes.text}>
                <CheckCircleOutlineIcon
                  fontSize="large"
                  color="inherit"
                />
                {" "}
                Gjøremål fullført.
              </Typography>
            </div>
          </Slide>
          <Typography variant="h4">Svar på et betalt spørsmål</Typography>
          <Typography>
            Dette spørsmålet har en kunde betalt for og krever et svar. Du kan gjenbruke gamle svar i tabellen
            nederst.
          </Typography>
          <br/>
          <br/>
          <TextField
            label="Pris"
            onChange={(e) => setSelectedResource({ ...selectedResource, ...{ price: e.target.value } })}
            type="number"
            value={selectedResource.price}
            className={classes.input}
            variant="outlined"
          />
          <TextField
            className={classes.input}
            label="Spørsmål"
            multiline
            onChange={(e) => setSelectedResource({
              ...selectedResource,
              ...{

                question: e.target.value

              }
            })}
            type="text"
            variant="outlined"
            value={selectedResource.question}
          />
          <TextField
            className={classes.input}
            label="Svar"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            multiline
            onChange={(e) => setSelectedResource({
              ...selectedResource,
              ...{

                answer: e.target.value

              }
            })}
            placeholder="Svar"
            type="text"
            value={selectedResource.answer}
          />
          <Tooltip title="Legger til standard innledning og avslutning">
            <Button onClick={() => addTemplate()}>Legg til mal</Button>
          </Tooltip>


          <Autocomplete
            defaultValue={selectedResource.categories}
            freeSolo
            id="tags-filled"
            multiple
            onInputChange={addTag}
            options={tags.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Emneknagger"
                margin="normal"
                placeholder="Søk etter eller lag nye..."
                variant="filled"
              />
            )}
            renderTags={(value, getTagProps) => {
              return (value.map((option, index) => (
                <Chip
                  label={option}
                  variant="outlined"
                  {...getTagProps({ index })}
                />
              )));
            }}
          />
          <br />
          <Tooltip title="Trykk og brukeren vil få tilsendt svaret du her skrevet."><Button variant="contained" onClick={() => updateResource()}>Send</Button></Tooltip><br/><br/>

          <FormControlLabel
            control={(
              <Switch
                checked={resourcePickerVisible}
                onChange={() => setResourcePickerVisible(!resourcePickerVisible)}
                value="checkedB"
                color="primary"
              />
            )}
            label="Vis søkefelt for gamle saker"
          /><br/>
          <ResourcePicker addResource={handleAddResource} visible={resourcePickerVisible}/>

        </Paper>
      </Zoom>
    );
  }
  return null;
};

export default withSnackbar(AnswerPromise);
