import config from './config';
import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';

export const tagService = {
  create,
  getAll,
  setInCase
};

function create(name) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ name })
  };
  return fetch(`${config.apiUrl}/tag/`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
  };
  return fetch(`${config.apiUrl}/tag/all`, requestOptions).then(handleResponse);
}

function setInCase(id, tags) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify({ id, tags })
  };
  return fetch(`${config.apiUrl}/tag/case/set`, requestOptions).then(handleResponse);
}

export default tagService;
