import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Lottie from "react-lottie";
import ContentSearch from "src/components/ContentSearch";
import ContractIcon from "@material-ui/icons/BorderColor";
import WizardIcon from "@material-ui/icons/AccountTree";
import Tooltip from "@material-ui/core/Tooltip";
import ContactIcon from "@material-ui/icons/Forum";
import GridActionButton from "../../components/GridAction/GridActionButton";
import VoiceChatIcon from "@material-ui/icons/VoiceChat";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  header: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: "40px 24px",
    [theme.breakpoints.up("lg")]: {
      padding: "80px 24px"
    }
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center"
  },
  mediaContainer: {
    margin: "0 auto",
    maxWidth: 1600,
    padding: theme.spacing(0, 2),
    overflow: "hidden"
  },
  media: {
    width: "100%"
  },
  stats: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1)
  },
  statsInner: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    margin: "0 auto"
  },
  spacer: {
    margin: 10
  },
  button: {
    textAlign: "center",
    width: "100%"
  },
  animated: {
    margin: "0 auto"
  },
  contractIcon: {
    color: "white"
  },
  wizardIcon: {
    color: "white"
  },
  link: {
    color: "black",
    fontWeight: 800,
    borderBottom: "1px dashed lightgrey"
  },
  divider: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid grey",
    lineHeight: "0.1em",
    margin: "80px 0px 40px 0px"
  },
  text: {
    padding: "0px 10px",
    background: "#fff",
    lineHeight: "0.1em"
  },
  fontSizeLarge: {
    fontSize: 140
  }
}));

function Header({ className, history, logo, name, ...rest }) {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.header}>
        <Grid container justify="center" alignItems="center" alignContent="center">
          <Grid item xs={12} md={6} jusitfy="center" alignItems="center" alignContent="center" container style={{ padding: 20 }}>
            {logo &&
            <>

              <div style={{width: '40%'}}>
                <img src={'/images/square-logo.png'} style={{width: '100%'}}/>
              </div>
              <div style={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h1" style={{fontSize: 60}}>+</Typography>
              </div>
              <div style={{width: '40%', display: 'flex', justifyContent: 'center'}}>
                <img src={logo} style={{width: '100%', height: 'auto'}}/>
              </div>
            </>
            }
            {!logo &&
            <Lottie options={{
              loop: false,
              autoplay: true,
              animationData: {
                "v": "5.2.1",
                "fr": 60,
                "ip": 0,
                "op": 120,
                "w": 800,
                "h": 600,
                "nm": "Comp 1",
                "ddd": 0,
                "assets": [],
                "layers": [{
                  "ddd": 0,
                  "ind": 1,
                  "ty": 4,
                  "nm": "Shape Layer 44",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [652, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [-100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[81.625, 199.625], [64.5, 188.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 2, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [2.375, -1], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[73, 205.75], [62.25, 199.25]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 2, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [2.375, -1], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[81, 203.25], [55.25, 186.75]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 2, "ix": 5 },
                      "lc": 2,
                      "lj": 2,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "tm",
                    "s": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "n": ["0p667_1_0p333_0"],
                        "t": 83,
                        "s": [0],
                        "e": [100]
                      }, { "t": 87 }],
                      "ix": 1
                    },
                    "e": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "n": ["0p667_1_0p333_0"],
                        "t": 79,
                        "s": [0],
                        "e": [100]
                      }, { "t": 83 }],
                      "ix": 2
                    },
                    "o": { "a": 0, "k": 0, "ix": 3 },
                    "m": 1,
                    "ix": 4,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 2,
                  "ty": 4,
                  "nm": "Shape Layer 43",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [400, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[81.625, 199.625], [64.5, 188.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 2, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [2.375, -1], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[73, 205.75], [62.25, 199.25]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 2, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [2.375, -1], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[81, 203.25], [55.25, 186.75]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 2, "ix": 5 },
                      "lc": 2,
                      "lj": 2,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "tm",
                    "s": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "n": ["0p667_1_0p333_0"],
                        "t": 83,
                        "s": [0],
                        "e": [100]
                      }, { "t": 87 }],
                      "ix": 1
                    },
                    "e": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "n": ["0p667_1_0p333_0"],
                        "t": 79,
                        "s": [0],
                        "e": [100]
                      }, { "t": 83 }],
                      "ix": 2
                    },
                    "o": { "a": 0, "k": 0, "ix": 3 },
                    "m": 1,
                    "ix": 4,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 3,
                  "ty": 4,
                  "nm": "Shape Layer 42",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 45, "ix": 10 },
                    "p": { "a": 0, "k": [515.625, 509.875, 0], "ix": 2 },
                    "a": { "a": 0, "k": [203.125, 200.625, 0], "ix": 1 },
                    "s": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [1, 1, 0.667], "y": [1, 1, 1] },
                        "o": { "x": [1, 1, 0.333], "y": [0, 0, 0] },
                        "n": ["1_1_1_0", "1_1_1_0", "0p667_1_0p333_0"],
                        "t": 65,
                        "s": [0, 0, 100],
                        "e": [191.465, 191.465, 100]
                      }, {
                        "i": { "x": [1, 1, 0.667], "y": [1, 1, 1] },
                        "o": { "x": [0, 0, 0.333], "y": [0, 0, 0] },
                        "n": ["1_1_0_0", "1_1_0_0", "0p667_1_0p333_0"],
                        "t": 77,
                        "s": [191.465, 191.465, 100],
                        "e": [191.465, 191.465, 100]
                      }, {
                        "i": { "x": [0, 0, 0.667], "y": [1, 1, 1] },
                        "o": { "x": [0, 0, 0.333], "y": [0, 0, 0] },
                        "n": ["0_1_0_0", "0_1_0_0", "0p667_1_0p333_0"],
                        "t": 81,
                        "s": [191.465, 191.465, 100],
                        "e": [0, 0, 100]
                      }, { "t": 90 }],
                      "ix": 6
                    }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[194.75, 191.5], [199.5, 200.25], [194.75, 209.75], [204, 205.25], [212.25, 209.75], [207.5, 199.75], [212.25, 191.25], [202.997, 195.882]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [203, 201], "ix": 2 },
                      "a": { "a": 0, "k": [203, 201], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 4,
                  "ty": 3,
                  "nm": "Null 2",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 0, "ix": 11 },
                    "r": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0], "y": [1] },
                        "o": { "x": [1], "y": [0] },
                        "n": ["0_1_1_0"],
                        "t": 56.5,
                        "s": [30],
                        "e": [0]
                      }, { "t": 84 }],
                      "ix": 10
                    },
                    "p": {
                      "a": 1,
                      "k": [{
                        "i": { "x": 0, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "n": "0_1_0p333_0",
                        "t": 0,
                        "s": [1055, 454.5, 0],
                        "e": [734, 454.5, 0],
                        "to": [-64.5, -44, 0],
                        "ti": [102.5, -71, 0]
                      }, { "t": 56.5 }],
                      "ix": 2
                    },
                    "a": { "a": 0, "k": [46.959, 54.054, 0], "ix": 1 },
                    "s": { "a": 0, "k": [296, 296, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 5,
                  "ty": 4,
                  "nm": "Shape Layer 36",
                  "parent": 4,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [-64.865, 1.689, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [33.088, 33.784, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [63.75, 54.25], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 10, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.823529471603, 0.635294117647, 0.235294132607, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [125.875, 154.875], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 6,
                  "ty": 4,
                  "nm": "Shape Layer 39",
                  "parent": 4,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [-65.878, 1.689, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [33.784, 33.784, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 7,
                  "ty": 4,
                  "nm": "Shape Layer 40",
                  "parent": 4,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [-65.878, 1.689, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [33.784, 33.784, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 12.25]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, -12.25]],
                          "v": [[332, 142], [180.25, 146.25], [174.75, 161.5], [331.5, 166.25], [343.5, 155.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [7.25, 30.5], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [179.125, 155], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [17.75, 23.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [166.875, 154.375], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [7.25, 40.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [159.875, 155.125], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 4,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 8,
                  "ty": 4,
                  "nm": "Shape Layer 38",
                  "parent": 4,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [-65.878, 1.689, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [33.784, 33.784, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [79.25, 22.5], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [126.125, 107.75], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [79.25, 22.5], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [126.125, 201], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 9,
                  "ty": 4,
                  "nm": "Shape Layer 37",
                  "parent": 4,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [-65.878, 1.689, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [33.784, 33.784, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [53.25, 75.5], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [125.875, 154.25], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [57.125, 3.125], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [126.063, 185.813], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 2",
                    "np": 4,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [57.125, 3.125], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 6, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [126.063, 123.813], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 10,
                  "ty": 4,
                  "nm": "Shape Layer 24",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 355.5, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [75.58, 75.58, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[279.375, 216.625], [110.875, 216.625], [110.844, 220.531], [279.375, 220.5]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313732386, 0.190177187324, 0.097962319851, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [8.25, 0], [0, 0], [0, 0], [0, 0], [0, 7]],
                          "o": [[0, 0], [-8.25, 0], [0, 0], [0, 0], [0, 0], [0, -7]],
                          "v": [[283, 220.5], [112, 220.5], [102.75, 227.75], [102.75, 239], [290.75, 240.5], [290.75, 229.5]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.345098039216, 0.248759610045, 0.202998845717, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [18.75, 8.25], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [89.607, 89.607], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [8.25, 0], [0, 0], [0, 0], [0, 0], [0, 7]],
                          "o": [[0, 0], [-8.25, 0], [0, 0], [0, 0], [0, 0], [0, -7]],
                          "v": [[283, 220.5], [112, 220.5], [102.75, 227.75], [102.75, 239], [290.75, 240.5], [290.75, 229.5]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.345098039216, 0.248759610045, 0.202998845717, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 11,
                  "ty": 4,
                  "nm": "Shape Layer 23",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [3, 0], [0, 0], [0, 0.5]],
                          "o": [[0, 0], [-3, 0], [0, 0], [0, -5.5]],
                          "v": [[-120.5, 90], [-169.5, 90], [-173.25, 96.25], [-116.25, 96.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.57647061348, 0.352941185236, 0.21960785985, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [2.5, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [244, 6.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.262745112181, 0, 0.06274510175, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-143.5, 106.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[47.5, 0], [16, -2.5], [0, 0], [0, 0], [0, 0], [-0.75, 0], [0, 0], [0, 0], [0, 0], [0, 9], [0, 0], [0, 5], [0, 0]],
                          "o": [[-47.5, 0], [-4.747, 0.179], [0, 0], [0, 0], [0, 0], [-8, 0], [0, 0], [0, 0], [0, 0], [0, -9], [0, 0], [0, -5], [0, 0]],
                          "v": [[-139.5, 81.25], [-257.75, 90], [-265, 97.75], [-265, 107.75], [-265.063, 110.25], [-267.75, 110.25], [-277.75, 118.25], [-277.75, 127.75], [-7.25, 127.75], [-7, 118.75], [-21.25, 110.5], [-21.25, 100.5], [-28, 92.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 12,
                  "ty": 4,
                  "nm": "Shape Layer 20",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [35.5, 19], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-143, -75.75], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [29.464, 29.464], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [35.5, 19], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313762188, 0.227450996637, 0.152941182256, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-143, -75.75], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 13,
                  "ty": 4,
                  "nm": "Shape Layer 17",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [46.838, 43.676, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-17.5, -225.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [11.988, 11.988, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [19.75, 19.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-17.375, -224.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [122.281, 122.281], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 14,
                  "ty": 4,
                  "nm": "Shape Layer 14",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [45.059, 44.664, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-17.5, -225.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [11.988, 11.988, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [19.75, 19.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-17.375, -224.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [122.281, 122.281], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 15,
                  "ty": 4,
                  "nm": "Shape Layer 19",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [234, 182, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-17.5, -225.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [30.329, 30.329, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [19.75, 19.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-17.375, -224.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [122.281, 122.281], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 16,
                  "ty": 4,
                  "nm": "Shape Layer 18",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [251.625, 412.5, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [19.75, 19.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-17.375, -224.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [122.281, 122.281], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 17,
                  "ty": 4,
                  "nm": "Shape Layer 16",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [54.743, 135.771, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [19.75, 19.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-17.375, -224.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [122.281, 122.281], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 18,
                  "ty": 4,
                  "nm": "Shape Layer 13",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [52.964, 135.771, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [19.75, 19.75], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 2",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-17.375, -224.625], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [122.281, 122.281], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 4,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 19,
                  "ty": 4,
                  "nm": "Shape Layer 12",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.81568627451, 0.81568627451, 0.81568627451, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 20,
                  "ty": 4,
                  "nm": "Shape Layer 15",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": -22.992, "ix": 10 },
                    "p": { "a": 0, "k": [241.75, 71.25, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-144, -214, 0], "ix": 1 },
                    "s": { "a": 0, "k": [18.987, 18.987, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [48.5, 48.5], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.576470588235, 0.352941176471, 0.219607858097, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-143.75, -212.25], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [80.583, 126.804], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 21,
                  "ty": 4,
                  "nm": "Shape Layer 11",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "d": 1,
                      "ty": "el",
                      "s": { "a": 0, "k": [48.5, 48.5], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "nm": "Ellipse Path 1",
                      "mn": "ADBE Vector Shape - Ellipse",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.380392186782, 0.227450995352, 0.149019607843, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-143.75, -212.25], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [80.583, 126.804], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Ellipse 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 22,
                  "ty": 4,
                  "nm": "Shape Layer 10",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "n": ["0p667_1_0p333_0"],
                        "t": 0,
                        "s": [2],
                        "e": [-2]
                      }, {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "n": ["0p667_1_0p333_0"],
                        "t": 60,
                        "s": [-2],
                        "e": [2]
                      }, { "t": 119 }],
                      "ix": 10
                    },
                    "p": { "a": 0, "k": [234, 79.75, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-135.5, -220.25, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-7.5, -231.5], [-262.5, -231.5], [-261.5, -219], [-135, -207], [-7.5, -219]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.262745098039, 0, 0.062745098039, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 23,
                  "ty": 4,
                  "nm": "Shape Layer 41",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 301.5, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [145.21, 100.851, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [663, 11], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.165782374962, 0.364366538852, 0.76862745098, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [2.5, 240.5], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 120,
                  "op": 120,
                  "st": 0,
                  "bm": 0,
                  "hidden": 0
                }, {
                  "ddd": 0,
                  "ind": 24,
                  "ty": 4,
                  "nm": "Shape Layer 1",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 301.5, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [111.677, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [14.775, 226], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.262745098039, 0, 0.062745098039, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [-128.72, -148], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 25,
                  "ty": 4,
                  "nm": "Shape Layer 9",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.25, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0]],
                          "v": [[-207.75, 175.25], [-211.25, 194.25], [-204, 194.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.403921604156, 0.254901975393, 0.015686275437, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 26,
                  "ty": 4,
                  "nm": "Shape Layer 8",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-147.25, 159.75], [-157.5, 159.75], [-161, 188], [-166.25, 160.75], [-172.75, 160.75], [-178.75, 188], [-181.5, 161.25], [-189.75, 161.25], [-185.5, 210.75], [-175.75, 210.75], [-169.75, 183.75], [-164.25, 210.5], [-154.75, 210.5]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0.564705882353, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-201.75, 160.5], [-211.75, 160.5], [-223.5, 210.25], [-213.75, 210.25], [-212.5, 200.5], [-201.25, 200.5], [-199.5, 209.75], [-190, 209.75]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0.564705882353, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-241.5, 161.25], [-250.75, 161.25], [-250.25, 210.5], [-226.25, 210.5], [-226.25, 201.75], [-241.5, 201.75]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.882352941176, 0.466375134038, 0.062283732844, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 0.564705882353, 0, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 120,
                  "op": 120,
                  "st": 0,
                  "bm": 0,
                  "hidden": 0
                }, {
                  "ddd": 0,
                  "ind": 27,
                  "ty": 4,
                  "nm": "Shape Layer 6",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.5, 302.5, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-66.5, 162], [-66.5, 163.75], [114.813, 163.75], [114.813, 162.188]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.678431372549, 0.678431372549, 0.678431372549, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 28,
                  "ty": 4,
                  "nm": "Shape Layer 5",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.5, 339, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-66.5, 162], [-66.5, 163.75], [114.813, 163.75], [114.813, 162.188]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.678431372549, 0.678431372549, 0.678431372549, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 29,
                  "ty": 4,
                  "nm": "Shape Layer 3",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[13.25, -1.5], [1.75, -43.75], [-37.25, 0], [0, 27.25], [0, 0]],
                          "o": [[-13.25, 1.5], [-1.75, 43.75], [-29.75, 0], [0, -27.25], [0, 0]],
                          "v": [[-100, 135], [-112.25, 191.5], [-65.75, 233.25], [-92.25, 167.75], [-75.25, 134]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.403921568627, 0.253869598987, 0.017424067329, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 30,
                  "ty": 4,
                  "nm": "Shape Layer 7",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [158.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, -29.5], [0, 0]],
                          "o": [[0, 0], [0, 29.5], [0, 0]],
                          "v": [[-113.5, 142], [-119, 181.5], [-116, 227.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.882352941176, 0.466375134038, 0.062283732844, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 31,
                  "ty": 4,
                  "nm": "Shape Layer 4",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, -29.5], [0, 0]],
                          "o": [[0, 0], [0, 29.5], [0, 0]],
                          "v": [[-113.5, 142], [-119, 181.5], [-116, 227.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.882352941176, 0.466375134038, 0.062283732844, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, -29.5], [0, 0]],
                          "o": [[0, 0], [0, 29.5], [0, 0]],
                          "v": [[-113.5, 142], [-119, 181.5], [-116, 227.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.882352941176, 0.466375134038, 0.062283732844, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [14, -22.25], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [-14, 22.25], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-55, 134.5], [-97.5, 150], [-93.5, 230], [109.75, 229.75], [109.75, 203], [115.5, 203], [115.5, 190.5], [115.5, 164], [119.25, 164], [119.25, 135.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 32,
                  "ty": 4,
                  "nm": "Shape Layer 2",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [318.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [236.75, 10], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.403921568627, 0.253869598987, 0.017424067329, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [6.375, 231.25], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ty": "rc",
                      "d": 1,
                      "s": { "a": 0, "k": [236.75, 10], "ix": 2 },
                      "p": { "a": 0, "k": [0, 0], "ix": 3 },
                      "r": { "a": 0, "k": 20, "ix": 4 },
                      "nm": "Rectangle Path 1",
                      "mn": "ADBE Vector Shape - Rect",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.403921568627, 0.253869598987, 0.017424067329, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [6.375, 132.75], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Rectangle 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": { "i": [[0, 0]], "o": [[0, 0]], "v": [[118, 138.75]], "c": false },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.403921568627, 0.253869598987, 0.017424067329, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, -57.5], [0, 0], [0, 0], [0, 48.5]],
                          "o": [[0, 0], [0, 0], [0, 40.5], [0, 0], [0, 0], [0, -39.5]],
                          "v": [[-90.5, 127.5], [-273, 128], [-290.5, 188.5], [-276.5, 236.5], [-91.5, 236.5], [-104, 177]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 7, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.403921568627, 0.253869598987, 0.017424067329, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 4,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 33,
                  "ty": 4,
                  "nm": "Shape Layer 22",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, -10.981], [-20.5, 0], [0, 47], [19.923, 0]],
                          "o": [[0, 40.75], [19.25, 0], [0, -10.863], [-24.077, 0]],
                          "v": [[-169.25, 32.75], [-143, 91], [-116.25, 28.25], [-141.923, 75.544]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.262745098039, 0, 0.062745098039, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 34,
                  "ty": 4,
                  "nm": "Shape Layer 21",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [378.5, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, -40.75], [-20.5, 0], [0, 47]],
                          "o": [[0, 0], [0, 40.75], [19.25, 0], [0, -47]],
                          "v": [[-143.25, -54.5], [-169.25, 32.75], [-143, 91], [-116.25, 28.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.384313755409, 0.227450995352, 0.152941176471, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 35,
                  "ty": 3,
                  "nm": "Null 1",
                  "parent": 22,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 0, "ix": 11 },
                    "r": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 0,
                        "s": [-2],
                        "e": [2]
                      }, {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 60,
                        "s": [2],
                        "e": [-2]
                      }, { "t": 119 }],
                      "ix": 10
                    },
                    "p": { "a": 0, "k": [-262.02, -226.206, 0], "ix": 2 },
                    "a": { "a": 0, "k": [47.818, 46.361, 0], "ix": 1 },
                    "s": { "a": 0, "k": [253, 253, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 36,
                  "ty": 4,
                  "nm": "Shape Layer 28",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [154.941, 143.281, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-237.863, -81.25], [-244.375, -67.125], [-232.25, -67.125], [-226.113, -80.875]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490196078, 0.845644004672, 0.308496722053, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 37,
                  "ty": 4,
                  "nm": "Shape Layer 26",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [46.443, 113.439, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-274.5, -75.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-223.3, -81.031], [-229.781, -66.969], [-227.25, -67.25], [-220.613, -81.031]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-246.113, -81], [-252.625, -67.125], [-249, -67.25], [-242.488, -81]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [-6.75, 0], [-9.25, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [6.75, 0], [9.25, 0]],
                          "v": [[-209.75, -81.5], [-248.25, -81.5], [-337.75, -81.5], [-331.75, -67.25], [-218.5, -67.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.823529411765, 0.636824783624, 0.235755471622, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 38,
                  "ty": 4,
                  "nm": "Shape Layer 27",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [46.443, 116.601, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-274.5, -75.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [36.531, 36.531, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [-6.75, 0], [-9.25, 0]],
                          "o": [[0, 0], [0, 0], [6.75, 0], [9.25, 0]],
                          "v": [[-209.75, -81.5], [-337.75, -81.5], [-331.75, -67.25], [-218.5, -67.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.666666666667, 0.41697809556, 0.149019592884, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 39,
                  "ty": 4,
                  "nm": "Shape Layer 30",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [154.941, 143.281, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[-335, -78.5], [-273.5, -243.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 2,
                      "lj": 2,
                      "d": [{ "n": "d", "nm": "dash", "v": { "a": 0, "k": 1, "ix": 1 } }, {
                        "n": "g",
                        "nm": "gap",
                        "v": { "a": 0, "k": 10, "ix": 2 }
                      }, { "n": "o", "nm": "offset", "v": { "a": 0, "k": 0, "ix": 7 } }],
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 40,
                  "ty": 4,
                  "nm": "Shape Layer 29",
                  "parent": 35,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [154.941, 143.281, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[-214.5, -78.5], [-273.5, -243.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 2,
                      "lj": 2,
                      "d": [{ "n": "d", "nm": "dash", "v": { "a": 0, "k": 1, "ix": 1 } }, {
                        "n": "g",
                        "nm": "gap",
                        "v": { "a": 0, "k": 10, "ix": 2 }
                      }, { "n": "o", "nm": "offset", "v": { "a": 0, "k": 0, "ix": 7 } }],
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 41,
                  "ty": 3,
                  "nm": "Null 1",
                  "parent": 22,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 0, "ix": 11 },
                    "r": {
                      "a": 1,
                      "k": [{
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 0,
                        "s": [2],
                        "e": [-2]
                      }, {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 60,
                        "s": [-2],
                        "e": [2]
                      }, { "t": 119 }],
                      "ix": 10
                    },
                    "p": { "a": 0, "k": [-10.191, -221.419, 0], "ix": 2 },
                    "a": { "a": 0, "k": [45.379, 48.253, 0], "ix": 1 },
                    "s": { "a": 0, "k": [253, 253, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 42,
                  "ty": 4,
                  "nm": "Shape Layer 33",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [154.941, 143.281, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-237.875, -82.125], [-244.375, -67.125], [-232.25, -67.125], [-226.125, -81.75]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490196078, 0.845644004672, 0.308496722053, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 43,
                  "ty": 4,
                  "nm": "Shape Layer 32",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [46.443, 113.439, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-274.5, -75.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-223.281, -81.469], [-229.781, -66.969], [-227.25, -67.25], [-220.594, -81.531]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 3",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                          "v": [[-246.125, -81.625], [-252.625, -67.125], [-249, -67.25], [-242.5, -81.375]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 2",
                    "np": 3,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }, {
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [0, 0], [-6.75, 0], [-9.25, 0]],
                          "o": [[0, 0], [0, 0], [0, 0], [6.75, 0], [9.25, 0]],
                          "v": [[-209.75, -81.5], [-248.25, -81.5], [-337.75, -81.5], [-331.75, -67.25], [-218.5, -67.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.823529411765, 0.636824783624, 0.235755471622, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 44,
                  "ty": 4,
                  "nm": "Shape Layer 31",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [46.443, 116.601, 0], "ix": 2 },
                    "a": { "a": 0, "k": [-274.5, -75.5, 0], "ix": 1 },
                    "s": { "a": 0, "k": [36.531, 36.531, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0], [-6.75, 0], [-9.25, 0]],
                          "o": [[0, 0], [0, 0], [6.75, 0], [9.25, 0]],
                          "v": [[-209.75, -81.5], [-337.75, -81.5], [-331.75, -67.25], [-218.5, -67.25]],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 4,
                      "ml2": { "a": 0, "k": 4, "ix": 8 },
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": true
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.666666666667, 0.41697809556, 0.149019592884, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 45,
                  "ty": 4,
                  "nm": "Shape Layer 35",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [154.941, 143.281, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[-335, -78.5], [-273.5, -243.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 2,
                      "lj": 2,
                      "d": [{ "n": "d", "nm": "dash", "v": { "a": 0, "k": 1, "ix": 1 } }, {
                        "n": "g",
                        "nm": "gap",
                        "v": { "a": 0, "k": 10, "ix": 2 }
                      }, { "n": "o", "nm": "offset", "v": { "a": 0, "k": 0, "ix": 7 } }],
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 46,
                  "ty": 4,
                  "nm": "Shape Layer 34",
                  "parent": 41,
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [154.941, 143.281, 0], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [39.526, 39.526, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "shapes": [{
                    "ty": "gr",
                    "it": [{
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [[0, 0], [0, 0]],
                          "o": [[0, 0], [0, 0]],
                          "v": [[-214.5, -78.5], [-273.5, -243.5]],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    }, {
                      "ty": "st",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 3, "ix": 5 },
                      "lc": 2,
                      "lj": 2,
                      "d": [{ "n": "d", "nm": "dash", "v": { "a": 0, "k": 1, "ix": 1 } }, {
                        "n": "g",
                        "nm": "gap",
                        "v": { "a": 0, "k": 10, "ix": 2 }
                      }, { "n": "o", "nm": "offset", "v": { "a": 0, "k": 0, "ix": 7 } }],
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    }, {
                      "ty": "fl",
                      "c": { "a": 0, "k": [0.925490255917, 0.847058883368, 0.309803921569, 1], "ix": 4 },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": true
                    }, {
                      "ty": "tr",
                      "p": { "a": 0, "k": [0, 0], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                  }],
                  "ip": 0,
                  "op": 120,
                  "st": 0,
                  "bm": 0
                }, {
                  "ddd": 0,
                  "ind": 47,
                  "ty": 1,
                  "nm": "Medium Gray-Blue Solid 1",
                  "sr": 1,
                  "ks": {
                    "o": { "a": 0, "k": 100, "ix": 11 },
                    "r": { "a": 0, "k": 0, "ix": 10 },
                    "p": { "a": 0, "k": [400, 300, 0], "ix": 2 },
                    "a": { "a": 0, "k": [640, 360, 0], "ix": 1 },
                    "s": { "a": 0, "k": [62.5, 111.111, 100], "ix": 6 }
                  },
                  "ao": 0,
                  "sw": 1280,
                  "sh": 720,
                  "sc": "#2e49a0",
                  "ip": 120,
                  "op": 120,
                  "st": 0,
                  "bm": 0,
                  "hidden": 0
                }],
                "markers": []
              },
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            />
            }
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              align="center"
              gutterBottom
              variant="h1"
            >
              {name &&


              <>Med {name} får du fordeler hos Legal24 advokatfirma</>
              } {!name &&
            <>              Juridisk rådgivning på nett
            </>
            }
            </Typography>
            <Typography
              align="center"
              component="h2"
              variant="subtitle1"
            >
              Legal24 e-sak tilbyr advokattjenester av kvalitet på nett, blant annet <Link className={classes.link}
                                                                                           to="/advokatchat">videosamtaler</Link>, <Link className={classes.link} to="/advokatchat">chat</Link>, <Link
              className={classes.link} to="/veiledere">automatisk veiledning</Link> og <Link className={classes.link}
                                                                                          to="/kontrakter/alle">automatisk
              kontraktgenerering</Link>.
            </Typography>

            <Grid container spacing={5} style={{marginTop: 20}}>
              <Grid item xs={6} md={6} lg={6} container justify='center'>
                <GridActionButton
                  badge='Gratis'
                  onClick={() => history.push("/advokatchat")}
                  icon={<ContactIcon fontSize='large' style={{fontSize: 70}}/>}
                >
                  Start chat
                </GridActionButton>
              </Grid>
              <Grid item xs={6} md={6} lg={6} container justify='center'>

                <GridActionButton
                  onClick={() => history.push("/advokatchat")}
                  icon={<VoiceChatIcon fontSize='large' style={{fontSize: 70}}/>}
                >
                  Start videosamtale
                </GridActionButton>
              </Grid>
              <Grid item xs={6} md={6} lg={6} container justify='center'>

                <GridActionButton
                  onClick={() => history.push("/kontrakter/alle")}
                  badge='Automatisk'
                  icon={<ContractIcon fontSize='large' style={{fontSize: 70}}/>}
                >
                  Lag kontrakt
                </GridActionButton>
              </Grid>
              <Grid item xs={6} md={6} lg={6} container justify='center'>

                <GridActionButton
                  badge='Automatisk'
                  onClick={() => history.push("/veiledere")}
                  icon={<WizardIcon
                    fontSize='large' style={{fontSize: 70}}/>}
                >
                  Juridisk veiledning
                </GridActionButton>
              </Grid>
            </Grid>


          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Grid>

      </div>
      <div className={classes.mediaContainer}>
        {/*

        <img
          alt="Demos"
          className={classes.media}
          src="/images/presentation/header.jpg"
        />
        */}
      </div>
      <div className={classes.stats}>

      </div>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default withRouter(Header);
