import React, {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/nb';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/styles';
import {
  colors, Link, TextField, Typography
} from '@material-ui/core';
import authenticationService from '../../../services/authentication.service';
import messageService from '../../../services/message.service';
import Attachment from "../Attachments/Attachment";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input";
import {withSnackbar} from "notistack";
const processString = require('react-process-string');

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  inner: {
    display: 'flex',
    maxWidth: 500,
    position: 'relative',
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  body: {
    backgroundColor: colors.grey[100],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: '8px 40px 8px 16px'
  },
  content: {
    marginTop: theme.spacing(1)
  },
  image: {
    marginTop: theme.spacing(2),
    height: 'auto',
    width: 380,
    maxWidth: '100%'
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  wordBreak: {
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontFamily: 'Roboto',
    fontSize: 14
  },
  link: {
    color: 'inherit',
    borderBottom: '1px dashed inherit'
  },
  editIcon: {
    position: 'absolute',
    right: 0,
    color: 'white'
  },
  editText: {
    color: 'inherit',
    width: 400,
    fontSize: 14,
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontFamily: 'Roboto',
  }
}));


function ConversationMessage({ message, enqueueSnackbar, className, ...rest }) {
  const classes = useStyles();
  const [textMessage, setMessage] = useState(message.message);

  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState(message.message);

  function isMySelf(id) {
    if(authenticationService.currentUserValue) {
      return id === authenticationService.currentUserValue.user._id;

    }else {
      return id === null;
    }
  }
  function isAdmin() {
    return authenticationService.currentUserValue ? authenticationService.currentUserValue.user.role === 'admin' : false;
  }



  const isAuthUser = () => {
    if(message.user) {
      if(authenticationService.currentUserValue) {
        return isMySelf(message.user._id) || (authenticationService.currentUserValue.user.role === 'admin' && message.user.role === 'admin')
      }else {
        return false;
      }
    }else {
      console.log(authenticationService.currentUserValue)
      if(authenticationService.currentUserValue !== null) {
        return false;
      }else {
        return true;
      }
    }
  };

  const name = () => {
    if(message.user) {
      if(authenticationService.currentUserValue) {
        return isMySelf(message.user._id) ? 'Meg' : message.user.name
      }else {
        return message.user.name;
      }
    }else {
      if(authenticationService.currentUserValue) {
          return 'Uidentifisert bruker'
      }else {
        return 'Meg'
      }
    }
  };

  function returnText(currentMessage) {
    let config = [{
      regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
      fn: (key, result) => <span key={key}>
                                     <a className={classes.link} href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                                 </span>
    }];

    return processString(config)(currentMessage);
  }

  useEffect(() => {
    setMessage(returnText(message.message));
  }, [message]);


  function handleEdit() {
    setEdit(true);
  }

  function handleKeyPress(e) {
    if (e.keyCode === 13) {
      if(e.shiftKey) {

      }else {
        e.preventDefault();
        setEdit(false);
        messageService.updateMessage(message._id, editText).then(() => {
          setMessage(returnText(editText));

        }).catch(err => {
            enqueueSnackbar('Du kan ikke redigere andres meldinger.', {
              variant: 'error'
            })
        })
      }
    }
  }



  return (
    <div
      {...rest}
      className={clsx(
        classes.root,
        {
          [classes.authUser]: isAuthUser()
        },
        className
      )}
    >
      <div className={classes.inner}>
        {isAdmin() &&
        <IconButton className={classes.editIcon} onClick={() => handleEdit()}>
          <EditIcon />
        </IconButton>
        }
        <div>

          <div className={classes.body}>
            <div>
              <Link
                color="inherit"
                component={RouterLink}
                variant="h6"
              >
                {name()}
              </Link>
            </div>
            <div className={classes.content}>
              {!edit &&

              <Typography
                color="inherit"
                variant="body1"
                className={classes.wordBreak}
              >
                {returnText(textMessage)}
              </Typography>
              }
              {edit &&
                <Input color="white" disableUnderline multiline className={classes.editText} onKeyDown={handleKeyPress} value={editText} onChange={(event) => setEditText(event.target.value)} label="Rediger tekst" />
              }
              {message.attachment
                &&         <Attachment caseId={message.conversation} attachment={message.attachment} createMode={false} />
              }
            </div>
          </div>
          <div className={classes.footer}>
            <Typography
              className={classes.time}
              variant="body2"
            >
              {
                moment(message.createdAt).locale('nb').fromNow()
              }
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

ConversationMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired
};

export default withSnackbar(ConversationMessage);
