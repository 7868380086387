import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { withSnackbar } from 'notistack';

import tagService from '../../../services/tags.service';
import resourceService from '../../../services/resource.service';
import { localization, tableIcons } from '../../config/table.config';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20
  },
  modal: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    marginLeft: -300
  },
  input: {
    width: '100%',
    marginBottom: 10
  },
  dialog: {
    width: 700
  },
  warning: {
    padding: 5
  }
}));

const ResourcePicker = ({ visible, addResource, enqueueSnackbar }) => {
  // TODO: Fix the slow state issue


  const classes = useStyles();


  const [selectedResource, setSelectedResource] = React.useState();
  const [tags, setTags] = React.useState([]);
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    tagService.getAll().then((result) => {
      setTags(result);
    });
  }, []);


  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (id) => {
    resourceService.getResourceById(id).then((data) => {
      setSelectedResource(data);
    }).catch((err) => {
      console.log(err);
    });
    setOpen(true);
  };

  function handleInsertOrUpdate() {
    if (selectedResource.type === 'oldanswer') {
      resourceService.convert(selectedResource._id, selectedResource, 'oldanswer').then((result) => {
        setSelectedResource(result);
        enqueueSnackbar('Ressursen er konvertert. Trykk "Legg ved" for å legge den til meldingen.', {
          variant: 'success'
        });
      });
    } else if (selectedResource.type === 'answer') {
      resourceService.updateResource(selectedResource._id, selectedResource);
      addResource(selectedResource._id, 'answer', selectedResource.answer);
      setOpen(false);
      enqueueSnackbar('Ressursen er satt inn.', {
        variant: 'success'
      });
    }
  }

  function copyResource() {
    if (selectedResource.type === 'answer') {
      resourceService.createResource(selectedResource.title, selectedResource.question, selectedResource.answer, selectedResource.type, selectedResource.categories, selectedResource.price).then((result) => {
        setSelectedResource(result);
      });
      enqueueSnackbar('Ressursen er kopiert. Du redigerer nå den nye kopien.', {
        variant: 'success'
      });
    }
  }


  function updateResource() {
    resourceService.updateResource(selectedResource._id, selectedResource).then(() => {
      enqueueSnackbar('Ressursen er oppdatert.', {
        variant: 'success'
      });
    });
  }

  const [currentTagInput, setCurrentTagInput] = useState('');

  function addTag(e, value, reason) {
    if (value !== '' && e.keyCode !== 13) {
      setCurrentTagInput(value);
    }
    if (e.keyCode === 13) {
      tagService.create(currentTagInput).then((tag) => {

      }).catch((err) => {
        // Tag already exists
      });
    }
  }

  function tagChange(e, value) {
    // console.log(e.type);

    const convertedArray = value.map((element) => element.charAt(0).toUpperCase() + element.slice(1));

    setSelectedResource({
      ...selectedResource,
      ...{
        categories: convertedArray
      }
    });
  }

  function addTemplate() {
    const oldValue = selectedResource.answer || '';

    setSelectedResource((selectedResource) => ({
      ...selectedResource,
      answer: `${'Legalis takker for registrering og bekreftelse for rådgivning. \n'
        + '\n'
        + 'Vi har nå sett nærmere på Deres henvendelse og kan komme med følgende tilbakemelding:\n\n'}${oldValue
      }\n\nVi håper De er fornøyd med tjenesten og at svaret kan bidra til å løse Deres juridiske problem. Ta gjerne kontakt hvis vi på noen måte kan være til ytterligere bistand. \n`
        + '\n'
        + 'Med vennlig hilsen\n'
        + 'Advokatfirmaet Legalis AS\n'
        + '[Navn Navnesen]\n'
        + 'advokat'
    }));
  }

  const columns = [
    { title: 'Spørsmål', field: 'question' },
    { title: 'Svar', field: 'answer' },
    { title: 'Opprettet', field: 'createdAt' },
    {
      title: '',
      render: (rowData) => (
        <Button
          variant="contained"
          onClick={() => addResource(rowData._id, 'answer', rowData.answer)}
        >
          Bruk svar som
          utgangspunkt
        </Button>

      )
    },
    {
      title: '',
      render: (rowData) => (
        <Button variant="contained" onClick={() => handleOpen(rowData._id)}>Rediger</Button>
      )
    }
  ];

  return (
    <div>
      {visible
      && (
        <div className={classes.root}>
          <>
            <MaterialTable
              columns={columns}
              data={(query) => new Promise((resolve, reject) => {
                resourceService.getResources(query).then((response) => {
                  resolve({
                    data: response.docs.map((elem) => ({
                      _id: elem._id,
                      title: elem.title,
                      answer: elem.answer,
                      question: elem.question,
                      createdAt: new Date(elem.createdAt).toLocaleString()
                    })),
                    page: response.page - 1,
                    totalCount: response.totalDocs

                  });
                });
              })}
              icons={tableIcons}
              localization={localization}
              title="Søk i eksisterende saker"
            />
          </>
        </div>
      )}
      {selectedResource
      && (
        <Dialog
          aria-labelledby="form-dialog-title"
          onClose={handleClose}
          open={open}
        >
          {selectedResource.type === 'oldanswer' && (
            <div className={classes.warning}>
              <Typography variant="body1">
                Advarsel! Denne er hentet fra våre gamle systemer og
                <b>MÅ</b>
                {' '}
                sees
                gjennom før den sendes til kunden
              </Typography>
              <Divider />
              <br />
              <br />
            </div>
          )}
          <DialogTitle id="form-dialog-title">
            <TextField
              className={classes.input}
              label="Tittel på svar"
              onChange={(e) => setSelectedResource({
                ...selectedResource,
                ...{
                  title: e.target.value
                }
              })}
              variant="outlined"
              type="text"
              value={selectedResource.title}
            />
          </DialogTitle>
          <DialogContent>

            <TextField
              label="Pris"
              onChange={(e) => setSelectedResource({ ...selectedResource, ...{ price: e.target.value } })}
              type="number"
              variant="outlined"
              className={classes.input}
              value={selectedResource.price}
            />
            <TextField
              className={classes.input}
              label="Spørsmål"
              variant="outlined"

              multiline
              onChange={(e) => setSelectedResource({
                ...selectedResource,
                ...{

                  question: e.target.value

                }
              })}
              placeholder="Spørsmål"
              type="text"
              value={selectedResource.question}
            />
            <TextField
              className={classes.input}
              label="Svar"
              variant="outlined"

              multiline
              onChange={(e) => setSelectedResource({
                ...selectedResource,
                ...{

                  answer: e.target.value

                }
              })}
              placeholder="Svar"
              type="text"
              value={selectedResource.answer}
            />
            <Button onClick={() => addTemplate()}>Legg til mal</Button>


            <Autocomplete
              value={selectedResource.categories}
              freeSolo
              className={classes.input}
              id="tags-filled"
              multiple
              onChange={tagChange}
              onInputChange={addTag}
              options={tags.map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Emneknagger"
                  margin="normal"
                  placeholder="Søk etter eller lag nye..."
                  variant="filled"
                />
              )}
              renderTags={(value, getTagProps) => (value.map((option, index) => (
                <Chip
                  label={option}
                  variant="outlined"
                  {...getTagProps({ index })}
                />
              )))}
            />
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
              Avbryt
            </Button>
            {selectedResource.type === 'answer' && (
              <>
                <Button
                  color="primary"
                  onClick={() => copyResource()}
                >
                  Lag en kopi
                </Button>
                <Button
                  color="primary"
                  onClick={() => updateResource()}
                >
                  Oppdater
                </Button>
              </>
            )}
            <Button
              color="primary"
              onClick={() => {
                handleInsertOrUpdate();
              }}
              variant="contained"
            >
              {selectedResource.type === 'oldanswer'
              && <>Godkjenn for bruk og legg ved</>}
              {selectedResource.type === 'answer'
              && <>Legg ved</>}
            </Button>
          </DialogActions>
        </Dialog>
      )}


    </div>
  );
};

export default withSnackbar(ResourcePicker);
