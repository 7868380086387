import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Divider} from '@material-ui/core';

import ConversationMessages from './ConversationMessages';
import ConversationForm from './ConversationForm';
import PromiseList from '../../../views/AdminConversation/components/PromiseList';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white
  },
  promiseWrapper: {
    padding: theme.spacing(3)
  },
  conversationList: {
    overflowY: 'scroll'
  }
}));

function ConversationDetails({
                               caseId, isRead, conversation, onCreate, onDelete, handleOpen, caseType, isOpen, message, setMessage, promises, onSend, mode, handleFileChange, onAddPromise, className, ...rest
                             }) {
  const classes = useStyles();

function calcHeight() {
  if(mode !== 'admin') {


    if (isOpen && mode !== 'unauth' && mode !== 'unauth_expanded') {
      return {height: 'calc(100vh - 374.2px)'}
    } else if (mode !== 'unauth') {
      return {height: 'calc(100vh - 130px)'}
    } else if (mode === 'unauth_expanded') {
      return {height: '100%'}
    } else {
      return {height: '100vh'}
    }
  }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Divider/>
      <ConversationMessages isRead={isRead} caseId={caseId} mode={mode} messages={conversation} className={classes.conversationList}
                            style={calcHeight()}

      />

      <Divider/>
      {mode === 'admin' && (
        <>
          {promises.filter((element) => (element.type === 'new' || element.type === 'video')).length > 0
          && (
            <div className={classes.promiseWrapper}>

              <PromiseList
                promises={promises}
                onCreate={onCreate}
                onDelete={onDelete}
                type={['new', 'video']}
                sendOffer={(message) => onSend(message)}
              />
            </div>
          )}
          {promises.filter((element) => (element.type === 'new' || element.type === 'video')).length === 0
          && (
            <ConversationForm
              mode={mode}
              onAddPromise={onAddPromise}
              onSend={onSend}
              caseType={caseType}
              isOpen={isOpen}
              handleOpen={handleOpen}
              message={message}
              setMessage={setMessage}
              multiline
              store
              handleFileChange={handleFileChange}
            />
          )}
        </>
      )}
      {mode !== 'admin' && (
        <ConversationForm
          mode={mode}
          isOpen={isOpen}
          message={message}
          handleOpen={handleOpen}
          setMessage={setMessage}
          onAddPromise={onAddPromise}
          onSend={onSend}
          handleFileChange={handleFileChange}
          multiline
        />
      )}


    </div>
  );
}

ConversationDetails.propTypes = {
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired,
  mode: PropTypes.object.isRequired,
};

export default ConversationDetails;
