/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';
import {ga_measurement_id} from "../services/config";


function GoogleAnalytics() {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${ga_measurement_id}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${ga_measurement_id}');
        `}
      </script>
    </Helmet>
  );
}

export default GoogleAnalytics;
