import React, {useEffect, useState} from 'react';
import {matchPath, useLocation} from 'react-router';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  colors, Divider, Drawer, Hidden, List, ListSubheader
} from '@material-ui/core';

import NavItem from 'src/components/NavItem';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ContractIcon from '@material-ui/icons/BorderColor';
import ChatIcon from '@material-ui/icons/Chat';

import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import WizardIcon from '@material-ui/icons/AccountTree';
import authenticationService from 'src/services/authentication.service';
import Typography from '@material-ui/core/Typography';
import ContactIcon from '@material-ui/icons/Forum';
import adminSettingsService from "../../services/adminsettings.service";
import DotIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  }
}));

function renderNavItems({
                          // eslint-disable-next-line react/prop-types
                          items, subheader, key, ...rest
                        }) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({acc, item, ...rest}),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
                             acc, pathname, item, depth = 0
                           }) {
  if (item.items) {
    let open = (matchPath(pathname, {
      path: item.href,
      exact: false
    }) || item.expanded);
    if(item.items.length > 0) {


    open = false;
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={true}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
    }else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      );
    }
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
      />
    );
  }

  return acc;
}

function NavBar({
                  openMobile,
                  onMobileClose,
                  className,
                  user,
                  authStatus,
                  ...rest
                }) {
  const classes = useStyles();
  const location = useLocation();


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const [wizards, setWizards] = useState([]);
  const [contracts, setContracts] = useState([]);


  useEffect(() => {
    adminSettingsService.getMenu('wizard').then(result => {
      setWizards(result);
    });
    adminSettingsService.getMenu('contract').then(result => {
      setContracts(result);
    });
  }, []);

  function formatLink(link) {
      let l = document.createElement("a");
      l.href = link;
      if (l.hostname === "localhost" || l.hostname === "e-sak.legal24.no" || l.hostname === "esak24.azurewebsites.net") {
        return l.pathname;

      } else {
        return link;
      }


  }


  const pages = () => {
    if (authStatus === 'admin') {
      return ([
        {
          items: [
            {
              title: 'Dashboard',
              href: '/dashboard',
              icon: DashboardIcon
            },
            {
              title: 'Brukeradministrasjon',
              href: '/users',
              icon: PeopleIcon
            },
            {
              title: 'Kontrakter',
              href: '/kontrakter/alle',
              icon: ContractIcon
            },
            {
              title: 'Advokatchat',
              href: '/adminConversationList/0',
              icon: ChatIcon
            },
            {
              title: 'Veiledere',
              href: '/veiledere',
              icon: WizardIcon
            },
            {
              title: 'Innstillinger',
              icon: SettingsIcon,
              items: [
                {
                  title: 'Rabatterte organisasjoner',
                  href: '/discounts',
                },
                {
                  title: 'Innstillinger',
                  href: '/adminsettings/general',
                }
              ]
            }
          ]
        }]);
    }
    if (authStatus === 'user') {
      return ([{
        items: [
          {
            title: 'Hjem',
            href: '/hjem',
            icon: AccountBoxIcon
          },
          {
            title: 'Advokatchat',
            href: '/mine-samtaler',
            icon: ContactIcon
          },
          {
            title: 'Kontrakter',
            href: '/kontrakter',
            icon: ContractIcon
          },
          {
            title: 'Veiledere',
            href: '/veiledere',
            icon: WizardIcon
          },
          {
            title: 'Innstillinger',
            href: '/innstillinger',
            icon: SettingsIcon
          }
        ]
      }]);
    }
    return ([{
      items: [
        {
          title: 'Hjem',
          href: '/',
          icon: HomeIcon
        },
        {
          title: 'Veiledere',
          href: '/veiledere',
          icon: WizardIcon
        },
        {
          title: 'Kontrakter',
          href: '/kontrakter/alle',
          icon: ContractIcon
        },
        {
          title: 'Advokatchat',
          href: '/advokatchat',
          icon: ContactIcon
        },
        {
          title: 'Logg inn',
          href: '/auth/login',
          icon: LockOpenIcon
        },
        {
          title: 'Registrer deg',
          href: '/auth/register',
          icon: MeetingRoomIcon
        }, ...wizards.map(element => ({
          title: element.title,
          href: formatLink(element.link),
        }))
      ],
    }]);
  };

  const content = (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <nav className={classes.navigation}>
        {pages().map((list) => renderNavItems({
          items: list.items,
          subheader: list.subheader,
          pathname: location.pathname,
          key: list.subheader
        }))}
      </nav>
      <Divider className={classes.divider}/>
      {authenticationService.currentUserValue
      && (
        <div className={classes.profile}>

          <div className={classes.details}>

            <Typography variant="h5">{authenticationService.currentUserValue.user.name}</Typography>
          </div>
        </div>
      )}

    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
