import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  media: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  link: {
  color: 'black',
  fontWeight: 800,
  borderBottom: '1px dashed #000'
}
}));

function VideoChatCommercial({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justify="space-between"
        >
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
          >
            <Typography
              gutterBottom
              variant="h3"
            >
              Juridisk rådgivning over video
            </Typography>
            <Typography variant="subtitle2">
              Start en <Link to="/advokatchat" className={classes.link}>videosamtale</Link> og løs ditt
              juridiske problem effektivt over video i nettleseren. Våre dyktige advokater er tilgjengelige innen kort tid.
            </Typography>
          </Grid>
          <Grid
            className={classes.media}
            item
            lg={4}
            md={6}
            xs={12}
          >
            <img
              alt="Juridisk rådgivning over nettet"
              src="/images/videochat.svg"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

VideoChatCommercial.propTypes = {
  className: PropTypes.string
};

export default VideoChatCommercial;
