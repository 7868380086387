import React from 'react';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    height: 60,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  copyright: {
    display: 'flex',
    textAlign: 'center',
    textDecoration: 'none',
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    fontSize: 10
  }
}));


const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography align="center" variant="caption" className={classes.copyright} gutterBottom>&copy;	Legal24 advokatfirma AS - {new Date().getFullYear()}</Typography>
      <Typography align="center" variant="caption" className={classes.copyright} gutterBottom>Universitetsgata 8, 0164 Oslo</Typography>
      <Typography align="center" variant="caption" className={classes.copyright}><a target="_blank" href="https://legal24advokat.no/personvernerklaering/" className={classes.copyright}>Personvernerklæring</a></Typography>

    </div>
  );
};

export default Footer;
