import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';


import { withSnackbar } from 'notistack';


import AccessTimeIcon from '@material-ui/icons/AccessTime';

import Grow from '@material-ui/core/Grow';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden'
  },
  createAnswer: {
  },
  input: {
    width: '100%'
  },
  overlay: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.warning.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.8',
  },
  text: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  fade: {
    opacity: '0.8'
  }
}));

const Promise = ({ promise, enqueueSnackbar }) => {
  const classes = useStyles();
  return (
    <Grow in>
      <Paper className={classes.root}>
        <div className={classes.overlay}>
          <Typography variant="h4" className={classes.text}>
            <AccessTimeIcon fontSize="large" color="inherit" />
            {' '}
Avventer brukers aksept.
          </Typography>
        </div>
        <Grid
          className={classes.createAnswer}
          container
          spacing={3}

        >
          <Grid
            xs={12}
            item
          >
            <Typography variant="h4">Gi tilbud.</Typography>
            <Typography>
              Her trenger du å oppgi kundes spørsmål, en selvvalgt tittel som gjør saken enkelt å søke opp og en pris
              kunden må betale for at dere skal besvare henvendelsen. Etter du har sendt tilbudet til kunden, og de så
              betaler, vil
              saken dukke opp under "Gjørmål" og besvares der når betaling er sikret.
            </Typography>
            <br />
            <TextField
              className={classes.input}
              multiline
              label="Skriv inn kundens spørsmål"
              variant="outlined"
              type="text"
              value={promise.question}
            />

          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.input}
              value={promise.price}
              label="Pris"
              type="number"
              variant="outlined"
            />

          </Grid>
        </Grid>
      </Paper>
    </Grow>
  );
};

export default withSnackbar(Promise);
