import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  media: {
    "& img": {
      width: "100%",
      height: "auto"
    }
  },
  link: {
    color: "black",
    fontWeight: 800,
    borderBottom: "1px dashed #000"
  },
  background: {
    backgroundColor: theme.palette.common.white
  },
  noBackground: {}
}));

function WizardCommercial({ className, transparent, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div
      {...rest}
      className={
        clsx(classes.root, className)
      }
      style={transparent ? null : { background: theme.palette.common.white }}
    >
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justify="space-between"
        >
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
          >
            <Typography
              gutterBottom
              variant="h3"
            >
              Automatiske juridiske veiledere
            </Typography>
            <Typography variant="subtitle2">
              Følg en <Link to="/veiledere" className={classes.link}>automatisk veileder</Link> og bli rettet i riktig
              retning med ditt juridiske spørsmål.
              Har vi ikke svaret på ditt spørsmål vil våre dyktige advokater kunne hjelpe deg hurtig basert på
              informasjonen du har oppgitt.
            </Typography>
          </Grid>
          <Grid
            className={classes.media}
            item
            lg={4}
            md={6}
            xs={12}
          >
            <img
              alt="Juridisk rådgivning"
              src="/images/conversation.svg"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

WizardCommercial.propTypes = {
  className: PropTypes.string
};

export default WizardCommercial;
