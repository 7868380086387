import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import WizardIcon from "@material-ui/icons/AccountTree";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { grey } from "@material-ui/core/colors";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    alignSelf: 'center'
  },
  icon: {
    height: "200%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  text: {
    height: "30%",
    textAlign: "center",
    marginTop: 10
  },
  badge: {
    [theme.breakpoints.up('xs')]: {

    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 40
    },
  },
  avatar: {
    height: 120,
    width: 120,
    background: grey[200],
    color: grey[700]
  }
}));

const GridActionButton = ({ icon, children, onClick, badge }) => {
  const classes = useStyles();
  return (
    <ButtonBase>
    <div className={classes.root} onClick={onClick}>
        <div className={classes.icon}>
          {badge &&
          <Badge classes={{badge: classes.badge}} badgeContent={badge}
                 color='secondary'><Avatar className={classes.avatar}>{icon}</Avatar></Badge>
          }
          {!badge &&
          <Avatar className={classes.avatar}>{icon}</Avatar>
          }

        </div>
        <div className={classes.text}>
          <Typography variant="h5">{children}</Typography>
        </div>
    </div>
    </ButtonBase>

  );
};

export default GridActionButton;
